.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  padding: 55px 0 0 60px;
  overflow: scroll;
  background: $background-color;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 55px 0px;
  }
  &::-webkit-scrollbar-track {
    //full
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    //bar
    opacity: 1;
    background-color: $main-color;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.header.session-header {
  margin-left: 10px;
  text-align: left;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1.3px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.1rem;
}
.header.session-sub-header {
  margin-left: 10px;
  text-align: left;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-weight: 800;
  letter-spacing: 1.3px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1rem;
}
