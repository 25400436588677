.banner-container {
  &.disable-banner {
    .body {
      background-color: $orange-color;
      .header > .tag {
        color: $orange-color;
      }
      .content {
        padding: 0px 10px 3px;
      }
    }
  }
  .body {
    padding: 15px;
    margin: 5px;
    background-color: $main-color;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 50px -10px rgba(0, 0, 0, 0.125);
    .header {
      padding: 0px 5px 3px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: block;
      }
      .title {
        font-size: 1.2rem;
        display: inline-block;
        padding-left: 5px;
        @media screen and (max-width: 768px) {
          display: block;
          padding-left: 0px;
        }
      }

      .tag {
        display: inline-block;
        font-size: 0.7rem;
        font-weight: 800;
        letter-spacing: 1px;
        background-color: rgba(white, 1);
        color: $main-color;
        padding: 4px 10px;
        transform: translateY(-2px);
        opacity: 0.8;
        //border-radius: 7px;
        //animation: blinker 0.5s linear infinite;
        @media screen and (max-width: 768px) {
          display: inline-block;
        }
      }
    }

    a.item,
    a.item:visited {
      border-radius: 6px;
      border: 2px solid white;
      padding: 4px 12px;
      margin: 3px;
      color: white;
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        text-decoration: none;
        background-color: white;
        color: $main-color;
        transition: 0.5s all ease;
      }
      @media screen and (max-width: 768px) {
        padding: 2px 5px;
        font-size: 0.8rem;
      }
    }
  }
}

@keyframes blinker {
  50% {
    background-color: rgba(white, 0.8);
    transform: scale(1.1) translateY(-1px);
  }
}
