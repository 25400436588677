//$green-color:  rgb(39, 174, 96);

.ui.small.modal.visible.active.main-modal.connect-modal {
  border-radius: 15px;
  .modal-body {
    padding: 40px 35px 30px;
  }
  .connect-group {
    display: flex;
    flex-direction: column;
    .item {
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 15px 15px;
      border-radius: 12px;
      margin: 5px;
      font-weight: 600;
      font-size: 1.1rem;
      cursor: pointer;
      user-select: none;
      //background-color: rgba(black, 0.03);
      border: 2px solid rgba(237, 238, 242, 1);
      .connected {
        display: none;
      }
      .left-item-container {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      &.true {
        background-color: rgba($green-color, 0.08);
        border-color: rgba($green-color, 0.08);
        .title {
          color: $main-color;
        }
        .connected {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          background-color: rgba($green-color, 1);
          margin-right: 10px;
        }
      }
      &:hover {
        border-color: $main-color;
      }
      &:active {
        transform: scale(0.98);
      }
    }
  }
}
