@import '../../assets/scss/variable.scss';

.time-selector.explorer {
  display: inline-block;
  select {
    display: inline-block;
    border-radius: 5px;
    padding: 4px 10px;
    margin-left: 0px;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    font-weight: 700;
    opacity: 0.7;
    cursor: pointer;
    outline: none;
    font-size: 1.2rem;
    &:hover {
      opacity: 1;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.explorer-market-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  @media screen and (max-width: map-get($breakpoints, large)) {
    flex-direction: column;
  }
  .loading-component {
    transform: scale(0.5);
    filter: grayscale(100%);
  }
  .info-card-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints, large)) {
      flex-direction: column;
    }
    .info-card {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      width: 25%;
      //box-shadow: 0px 1px 50px -10px rgba(0, 0, 0, 0.125);
      margin: 5px;
      @media screen and (max-width: map-get($breakpoints, large)) {
        width: 100%;
      }
      .title {
        letter-spacing: 0.5px;
      }
      .content {
        margin-top: 5px;
        .main {
          font-size: 2.5rem;
          font-weight: 700;
        }
        .unit {
          font-size: 1rem;
          font-weight: 400;
        }
        .notes {
          font-size: 1rem;
          font-weight: 400;
        }
      }
      .buy-txn-graph {
        overflow: hidden hidden;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: Space-between;
        height: 60px;
        margin-top: 20px;
        .bar {
          background: rgba(0, 0, 0, 0.1);
          margin-right: 1px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          width: 100%;
          &:hover {
            background-color: $main-color;
          }
        }
      }
    }
  }
}
