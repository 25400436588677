.info-nav {
  position: absolute;
  z-index: 4;
  bottom: -2px;
  padding: 1px;
  right: 5px;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: rgba(white, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 0px 2px rgba(137, 137, 137, 0.1);
  color: rgba(black, 0.6);
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  a,
  div {
    color: black;
    font-weight: 500;
    padding: 3px 10px;
    cursor: pointer;
    line-height: 16px;
    left: 3px;
    text-decoration: none;
    &:hover {
      color: rgba(black, 1);
    }
  }
}
.top-nav {
  position: absolute;
  z-index: 5;
  width: 100vw;
  padding: 0 10px 0 60px;
  height: 60px;
  color: $main-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(white, 0.7);
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 0px rgba(137, 137, 137, 0.05);
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 10px 15px;
  }
  .mobile-logo img {
    width: 30px;
    color: $main-color;
  }
  .title {
    font-size: 1.3rem;
    font-weight: 700;
  }
  .left-group {
    text-align: right;
    display: flex;
    flex-direction: row;
    @media (max-width: 984px) {
      justify-content: space-between;
      .item {
        padding: 0 20px;
        display: none;
      }
    }
    .item {
      padding: 0 20px;
      .category {
        color: rgb(119, 119, 119);
      }
      .data {
        color: black;
        display: flex;
        align-items: center;
      }
      span.price-percentage-indicator {
        font-weight: 700;
        font-size: 1rem;
        color: rgb(23, 196, 16);
        padding: 0px 3px;
      }
      span.price-percentage-indicator-length {
        font-size: 0.7rem;
        color: black;
        background-color: rgba(black, 0.05);
        border-radius: 15px;
        padding: 1px 5px;
      }
    }
  }
  .right-group {
    display: flex;
    flex-direction: row;
  }
  .item.connect-item {
    align-self: center;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 9px 16px;
    background-color: rgba($main-color, 1);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    text-transform: uppercase;
    &:hover {
      background-color: lighten(rgba($main-color, 1), 10%);
    }
    //&.empty-wallet {
    //  box-shadow: inset 0px 0px 0px 2px $main-color;
    //  background-color: rgba(white,1);
    //  color: $main-color;
    //  &:hover {
    //    background-color: rgba($main-color,1);
    //    color: white;
    //    opacity: 0.9;
    //  }
    //  &:active {
    //    transform: scale(0.98);
    //  }
    //}
  }
  .item {
    &.humberger {
      margin-left: 10px;
      img {
        display: inline-block;
        height: 34px;
      }
    }
    #language-dropdown {
      cursor: pointer;
      display: block;
      font-size: 1rem;
      line-height: 1.2rem;
      border: none;
      border-radius: 10px;
      color: #444;
      font-weight: bold;
      padding: 9px 25px 9px 16px;
      max-width: 100%;
      margin-right: 10px;
      height: 34px;
      text-align: center;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('https://img.icons8.com/ios-filled/50/000000/expand-arrow.png');
      background-color: rgba(0, 0, 0, 0.05);
      background-repeat: no-repeat, repeat;
      background-position: right 0.75em top 50%, 0 0;
      background-size: 0.65em auto, 100%;
      &:focus {
        border: none;
        outline: none;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.side-nav,
.side-nav.false {
  @media screen and (max-width: map-get($breakpoints, small)) {
    left: -250px;
    transition: all 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1);
  }
}
.side-nav.true {
  transition: width 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1);
  @media screen and (max-width: map-get($breakpoints, small)) {
    left: 0px;
    transition: width 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1);
  }
}

.side-nav {
  position: absolute;
  z-index: 6;
  height: 100vh;
  width: 60px;
  background-color: rgba(white, 1);
  padding-top: 3px;
  transition: width 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1);
  .item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .nav-logo {
      padding: 16px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 55px;
      width: 60px;
      justify-content: center;
      margin-bottom: 70px;
      img {
        height: 30px;
      }
      .logo-title {
        display: none;
        font-size: 0;
        opacity: 0;
        color: $main-color;
        font-weight: 700;
      }
    }
    .top-group {
    }
    .bottom-group {
      display: none;
    }

    .item {
      padding: 15px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      justify-content: center;
      cursor: pointer;
      width: 60px;

      &:hover {
        text-decoration: none;
      }

      img {
        height: 25px;
      }
      .nav-title {
        font-size: 0;
        opacity: 0;
        color: black;
      }
      &.active {
        border-left: 3px solid white;
      }
      &:hover {
        background-color: lighten($main-color, 80%);
        transition: background-color 0.5s;
      }
    }
  }
  .item-container .item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 0 15px;
    width: 60px;
    transition: width 0.37s cubic-bezier(0.05, 0.74, 0.21, 1.1);
    &:hover {
      //background-color: rgba(49, 131, 137, 0.05);
    }
    &.active {
      box-shadow: inset -5px 0px 0px $main-color;
      transition: all 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1);
      background-color: rgba(49, 131, 137, 0.05);
    }
  }
  &:hover,
  &.true {
    width: 250px;
    box-shadow: 2px 0px 0px rgba(137, 137, 137, 0.03),
      4px 0px 40px rgba(137, 137, 137, 0.3);
    .item-container {
      align-items: flex-start;
      // padding:0 5px;
    }
    .item-container .item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      padding: 0 15px;
      width: 250px;

      &:hover {
        //background-color: rgba(49, 131, 137, 0.05);
        .nav-title {
          color: $main-color;
        }
      }
      &:active {
        background-color: rgba(49, 131, 137, 0.05);
      }
      &.active {
        box-shadow: inset -5px 0px 0px $main-color;
        background-color: rgba(49, 131, 137, 0.05);
      }
    }
    .item-container .nav-logo {
      justify-content: left;
      width: 210px;
      padding: 0 15px;
    }
    .item-container .nav-logo .logo-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 200px;
    }
    .item-container .item .nav-title,
    .item-container .nav-logo .logo-title {
      opacity: 1;
      font-size: 1.3rem;
      padding-left: 20px;
    }

    .bottom-group {
      display: flex;
      flex-direction: row;
      width: 100%;
      .info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-width: 200px;
        .info-item {
          display: flex;
          flex-direction: row;
          font-size: 12px;
          padding: 3px;
          color: $main-color;
          .info-link {
            font-weight: 600;
            color: $main-color;
            &.hover {
              color: $main-color;
            }
          }
          .info-dot {
            padding: 0 3px;
          }
        }
      }
    }
  }
}

.dex-selection {
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1.3rem;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 5px 30px 5px 10px;
  margin-left: 10px;
  text-align: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../images/eth-select.svg');
  background-repeat: no-repeat, repeat;
  &:focus {
    border: none;
    outline: none;
  }
  &:hover {
    overlay: rgba(255, 255, 255, 0.5);
  }
  option {
    color: black;
  }
}
