.ui.dimmer.modals.visible.active {
  background: rgba(white, 0.5);
  backdrop-filter: blur(2px);
}
.ui.small.modal.visible.active.main-modal {
  height: auto !important;
  width: 40vw !important;
  margin: 20vh 30vw !important;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    width: 90vw !important;
    margin: 10vh 5vw !important;
  }
  &.main-modal-wide {
    width: 60vw !important;
    margin: 20vh 20vw !important;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 90vw !important;
      margin: 10vh 5vw !important;
    }
  }
  .modal-body {
    padding: 40px 20px 20px;
  }
  .equivalent-container {
    .sub-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 3px;
      color: black;
      font-weight: 600;
      small {
        margin-left: 3px;
        opacity: 0.7;
      }
      input {
        width: 60px;
        text-align: right;
        margin-right: 5px;
        font-weight: 800;
        font-family: 'Roboto Mono' !important;
      }
    }
  }
  .modal-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px auto 0px;
    .bg-modal-button {
      margin-right: 0px;
      border-radius: 10px;
      font-size: 1.3rem;
      opacity: 0.8;
      &.highlight {
        color: $main-color;
      }
      &.cancel {
        opacity: 0.5;
      }
      &:hover {
        opacity: 0.9;
        .metamask-icon {
          transform: translateY(-2px) scale(1.2);
        }
      }
      .metamask-icon {
        margin-left: 5px;
        transform: translateY(-2px) scale(1);
      }
    }
  }

  .modal-title {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: black;
    box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.5);
    color: white;
    background-color: $main-color;
  }
  input.modal-input {
    display: block;
    font-family: 'Roboto Mono' !important;
    font-size: 1.4rem;
    width: 100%;
    padding: 15px 15px;
    outline: none;
    border-radius: 8px;
    border: none;
    background: rgba(0, 0, 0, 0.04);
    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }
  }
  .top-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    .input-label {
      // margin: 10px auto;
      font-size: 1.3rem;
      font-weight: 500;
      display: block;
      width: 100%;
      text-align: left;
    }
    .btn.max-btn {
      font-weight: bold;
      background-color: $background-color;
      border-radius: 8px;
      &:hover {
        background-color: $main-color;
        color: white;
        transition: 0.1s all;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
