.join-modal {
  .join-modal-inner {
    padding: 1rem;
    .social-link-title {
      font-size: 24px;
      font-weight: 600;
    }
    .social-link-container {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      .social-link {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: $main-color;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        background-color: #f6f6f6;
        margin-top: 10px;
        &:hover {
          text-decoration: none;
          background-color: darken(#f6f6f6, 6%);
        }
      }
    }
  }
}
