@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap);
.info-nav {
  position: absolute;
  z-index: 4;
  bottom: -2px;
  padding: 1px;
  right: 5px;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0px 0px 0px 2px rgba(137, 137, 137, 0.1);
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.7; }
  .info-nav:hover {
    opacity: 1; }
  .info-nav a,
  .info-nav div {
    color: black;
    font-weight: 500;
    padding: 3px 10px;
    cursor: pointer;
    line-height: 16px;
    left: 3px;
    text-decoration: none; }
    .info-nav a:hover,
    .info-nav div:hover {
      color: black; }

.top-nav {
  position: absolute;
  z-index: 5;
  width: 100vw;
  padding: 0 10px 0 60px;
  height: 60px;
  color: #2a647b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0px 2px 0px rgba(137, 137, 137, 0.05); }
  @media screen and (max-width: 480px) {
    .top-nav {
      padding: 10px 15px; } }
  .top-nav .mobile-logo img {
    width: 30px;
    color: #2a647b; }
  .top-nav .title {
    font-size: 1.3rem;
    font-weight: 700; }
  .top-nav .left-group {
    text-align: right;
    display: flex;
    flex-direction: row; }
    @media (max-width: 984px) {
      .top-nav .left-group {
        justify-content: space-between; }
        .top-nav .left-group .item {
          padding: 0 20px;
          display: none; } }
    .top-nav .left-group .item {
      padding: 0 20px; }
      .top-nav .left-group .item .category {
        color: #777777; }
      .top-nav .left-group .item .data {
        color: black;
        display: flex;
        align-items: center; }
      .top-nav .left-group .item span.price-percentage-indicator {
        font-weight: 700;
        font-size: 1rem;
        color: #17c410;
        padding: 0px 3px; }
      .top-nav .left-group .item span.price-percentage-indicator-length {
        font-size: 0.7rem;
        color: black;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 1px 5px; }
  .top-nav .right-group {
    display: flex;
    flex-direction: row; }
  .top-nav .item.connect-item {
    align-self: center;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 9px 16px;
    background-color: #2a647b;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    text-transform: uppercase; }
    .top-nav .item.connect-item:hover {
      background-color: #3783a1; }
  .top-nav .item.humberger {
    margin-left: 10px; }
    .top-nav .item.humberger img {
      display: inline-block;
      height: 34px; }
  .top-nav .item #language-dropdown {
    cursor: pointer;
    display: block;
    font-size: 1rem;
    line-height: 1.2rem;
    border: none;
    border-radius: 10px;
    color: #444;
    font-weight: bold;
    padding: 9px 25px 9px 16px;
    max-width: 100%;
    margin-right: 10px;
    height: 34px;
    text-align: center;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("https://img.icons8.com/ios-filled/50/000000/expand-arrow.png");
    background-color: rgba(0, 0, 0, 0.05);
    background-repeat: no-repeat, repeat;
    background-position: right 0.75em top 50%, 0 0;
    background-size: 0.65em auto, 100%; }
    .top-nav .item #language-dropdown:focus {
      border: none;
      outline: none; }
    .top-nav .item #language-dropdown:hover {
      background-color: rgba(0, 0, 0, 0.08); }

@media screen and (max-width: 480px) {
  .side-nav,
  .side-nav.false {
    left: -250px;
    transition: all 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1); } }

.side-nav.true {
  transition: width 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1); }
  @media screen and (max-width: 480px) {
    .side-nav.true {
      left: 0px;
      transition: width 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1); } }

.side-nav {
  position: absolute;
  z-index: 6;
  height: 100vh;
  width: 60px;
  background-color: white;
  padding-top: 3px;
  transition: width 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1); }
  .side-nav .item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .side-nav .item-container .nav-logo {
      padding: 16px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 55px;
      width: 60px;
      justify-content: center;
      margin-bottom: 70px; }
      .side-nav .item-container .nav-logo img {
        height: 30px; }
      .side-nav .item-container .nav-logo .logo-title {
        display: none;
        font-size: 0;
        opacity: 0;
        color: #2a647b;
        font-weight: 700; }
    .side-nav .item-container .bottom-group {
      display: none; }
    .side-nav .item-container .item {
      padding: 15px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      justify-content: center;
      cursor: pointer;
      width: 60px; }
      .side-nav .item-container .item:hover {
        text-decoration: none; }
      .side-nav .item-container .item img {
        height: 25px; }
      .side-nav .item-container .item .nav-title {
        font-size: 0;
        opacity: 0;
        color: black; }
      .side-nav .item-container .item.active {
        border-left: 3px solid white; }
      .side-nav .item-container .item:hover {
        background-color: white;
        transition: background-color 0.5s; }
  .side-nav .item-container .item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 0 15px;
    width: 60px;
    transition: width 0.37s cubic-bezier(0.05, 0.74, 0.21, 1.1); }
    .side-nav .item-container .item.active {
      box-shadow: inset -5px 0px 0px #2a647b;
      transition: all 0.3s cubic-bezier(0.05, 0.74, 0.21, 1.1);
      background-color: rgba(49, 131, 137, 0.05); }
  .side-nav:hover, .side-nav.true {
    width: 250px;
    box-shadow: 2px 0px 0px rgba(137, 137, 137, 0.03), 4px 0px 40px rgba(137, 137, 137, 0.3); }
    .side-nav:hover .item-container, .side-nav.true .item-container {
      align-items: flex-start; }
    .side-nav:hover .item-container .item, .side-nav.true .item-container .item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      padding: 0 15px;
      width: 250px; }
      .side-nav:hover .item-container .item:hover .nav-title, .side-nav.true .item-container .item:hover .nav-title {
        color: #2a647b; }
      .side-nav:hover .item-container .item:active, .side-nav.true .item-container .item:active {
        background-color: rgba(49, 131, 137, 0.05); }
      .side-nav:hover .item-container .item.active, .side-nav.true .item-container .item.active {
        box-shadow: inset -5px 0px 0px #2a647b;
        background-color: rgba(49, 131, 137, 0.05); }
    .side-nav:hover .item-container .nav-logo, .side-nav.true .item-container .nav-logo {
      justify-content: left;
      width: 210px;
      padding: 0 15px; }
    .side-nav:hover .item-container .nav-logo .logo-title, .side-nav.true .item-container .nav-logo .logo-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 200px; }
    .side-nav:hover .item-container .item .nav-title,
    .side-nav:hover .item-container .nav-logo .logo-title, .side-nav.true .item-container .item .nav-title,
    .side-nav.true .item-container .nav-logo .logo-title {
      opacity: 1;
      font-size: 1.3rem;
      padding-left: 20px; }
    .side-nav:hover .bottom-group, .side-nav.true .bottom-group {
      display: flex;
      flex-direction: row;
      width: 100%; }
      .side-nav:hover .bottom-group .info-container, .side-nav.true .bottom-group .info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-width: 200px; }
        .side-nav:hover .bottom-group .info-container .info-item, .side-nav.true .bottom-group .info-container .info-item {
          display: flex;
          flex-direction: row;
          font-size: 12px;
          padding: 3px;
          color: #2a647b; }
          .side-nav:hover .bottom-group .info-container .info-item .info-link, .side-nav.true .bottom-group .info-container .info-item .info-link {
            font-weight: 600;
            color: #2a647b; }
            .side-nav:hover .bottom-group .info-container .info-item .info-link.hover, .side-nav.true .bottom-group .info-container .info-item .info-link.hover {
              color: #2a647b; }
          .side-nav:hover .bottom-group .info-container .info-item .info-dot, .side-nav.true .bottom-group .info-container .info-item .info-dot {
            padding: 0 3px; }

.dex-selection {
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1.3rem;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 5px 30px 5px 10px;
  margin-left: 10px;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(../../static/media/eth-select.894591e0.svg);
  background-repeat: no-repeat, repeat; }
  .dex-selection:focus {
    border: none;
    outline: none; }
  .dex-selection:hover {
    overlay: rgba(255, 255, 255, 0.5); }
  .dex-selection option {
    color: black; }

.dashboard-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  padding: 55px 0 0 60px;
  overflow: scroll;
  background: rgba(49, 131, 137, 0.02); }
  @media screen and (max-width: 480px) {
    .dashboard-container {
      padding: 55px 0px; } }
  .dashboard-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px; }
  .dashboard-container::-webkit-scrollbar-thumb {
    opacity: 1;
    background-color: #2a647b;
    cursor: pointer; }
  .dashboard-container::-webkit-scrollbar {
    width: 0px; }

.header.session-header {
  margin-left: 10px;
  text-align: left;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1.3px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.1rem; }

.header.session-sub-header {
  margin-left: 10px;
  text-align: left;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-weight: 800;
  letter-spacing: 1.3px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1rem; }

.time-selector.explorer {
  display: inline-block; }
  .time-selector.explorer select {
    display: inline-block;
    border-radius: 5px;
    padding: 4px 10px;
    margin-left: 0px;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    font-weight: 700;
    opacity: 0.7;
    cursor: pointer;
    outline: none;
    font-size: 1.2rem; }
    .time-selector.explorer select:hover {
      opacity: 1; }
  @media (max-width: 767px) {
    .time-selector.explorer {
      margin-bottom: 10px; } }

.explorer-market-info {
  display: flex;
  width: 100%;
  flex-direction: row; }
  @media screen and (max-width: 1000px) {
    .explorer-market-info {
      flex-direction: column; } }
  .explorer-market-info .loading-component {
    transform: scale(0.5);
    filter: grayscale(100%); }
  .explorer-market-info .info-card-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 1000px) {
      .explorer-market-info .info-card-group {
        flex-direction: column; } }
    .explorer-market-info .info-card-group .info-card {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      width: 25%;
      margin: 5px; }
      @media screen and (max-width: 1000px) {
        .explorer-market-info .info-card-group .info-card {
          width: 100%; } }
      .explorer-market-info .info-card-group .info-card .title {
        letter-spacing: 0.5px; }
      .explorer-market-info .info-card-group .info-card .content {
        margin-top: 5px; }
        .explorer-market-info .info-card-group .info-card .content .main {
          font-size: 2.5rem;
          font-weight: 700; }
        .explorer-market-info .info-card-group .info-card .content .unit {
          font-size: 1rem;
          font-weight: 400; }
        .explorer-market-info .info-card-group .info-card .content .notes {
          font-size: 1rem;
          font-weight: 400; }
      .explorer-market-info .info-card-group .info-card .buy-txn-graph {
        overflow: hidden hidden;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: Space-between;
        height: 60px;
        margin-top: 20px; }
        .explorer-market-info .info-card-group .info-card .buy-txn-graph .bar {
          background: rgba(0, 0, 0, 0.1);
          margin-right: 1px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          width: 100%; }
          .explorer-market-info .info-card-group .info-card .buy-txn-graph .bar:hover {
            background-color: #2a647b; }

.dashboard-market-info {
  display: none; }
  @media screen and (max-width: 1000px) {
    .dashboard-market-info {
      display: inline-block;
      width: 100%; } }
  .dashboard-market-info .info-card-group {
    display: none;
    flex-direction: row;
    justify-content: space-between; }
    .dashboard-market-info .info-card-group .info-card {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      width: 100%;
      margin: 5px; }
      .dashboard-market-info .info-card-group .info-card:first-child {
        margin-left: 0px; }
      .dashboard-market-info .info-card-group .info-card:last-child {
        margin-right: 0px; }
      .dashboard-market-info .info-card-group .info-card span.price-percentage-indicator {
        font-weight: 700;
        font-size: 1rem;
        color: #17c410;
        padding: 0px 3px; }
      .dashboard-market-info .info-card-group .info-card span.price-percentage-indicator-length {
        font-size: 0.7rem;
        color: black;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 1px 5px; }
    @media screen and (max-width: 1000px) {
      .dashboard-market-info .info-card-group {
        display: flex; } }

.wallet-container,
.explorer-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }
  .wallet-container .container,
  .explorer-component .container {
    padding: 20px 5px; }
  .wallet-container .dashboard-session,
  .explorer-component .dashboard-session {
    padding: 10px 0px; }
    .wallet-container .dashboard-session .header.session-header,
    .explorer-component .dashboard-session .header.session-header {
      margin-left: 10px;
      text-align: left;
      font-family: 'Open Sans', 'Roboto', sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem; }
  .wallet-container .balance-cards,
  .explorer-component .balance-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end; }
    .wallet-container .balance-cards .balance-card-outter-container,
    .explorer-component .balance-cards .balance-card-outter-container {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 5px;
      width: 100%; }
      @media screen and (max-width: 850px) {
        .wallet-container .balance-cards .balance-card-outter-container,
        .explorer-component .balance-cards .balance-card-outter-container {
          padding: 0px;
          padding-bottom: 10px; } }
      .wallet-container .balance-cards .balance-card-outter-container.drawer-open-true,
      .explorer-component .balance-cards .balance-card-outter-container.drawer-open-true {
        transition: 0.1s all ease-in-out;
        margin-top: 30px; }
        @media screen and (max-width: 850px) {
          .wallet-container .balance-cards .balance-card-outter-container.drawer-open-true,
          .explorer-component .balance-cards .balance-card-outter-container.drawer-open-true {
            margin-top: 40px; } }
        .wallet-container .balance-cards .balance-card-outter-container.drawer-open-true:hover .balance-card-background-drawer,
        .explorer-component .balance-cards .balance-card-outter-container.drawer-open-true:hover .balance-card-background-drawer {
          top: -33px; }
        .wallet-container .balance-cards .balance-card-outter-container.drawer-open-true .balance-card-container,
        .explorer-component .balance-cards .balance-card-outter-container.drawer-open-true .balance-card-container {
          box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px; }
        .wallet-container .balance-cards .balance-card-outter-container.drawer-open-true .balance-card-background-drawer,
        .explorer-component .balance-cards .balance-card-outter-container.drawer-open-true .balance-card-background-drawer {
          top: -25px; }
          @media screen and (max-width: 850px) {
            .wallet-container .balance-cards .balance-card-outter-container.drawer-open-true .balance-card-background-drawer,
            .explorer-component .balance-cards .balance-card-outter-container.drawer-open-true .balance-card-background-drawer {
              top: -33px; } }
    .wallet-container .balance-cards .balance-card-background-drawer,
    .explorer-component .balance-cards .balance-card-background-drawer {
      background-color: white;
      margin: auto;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      position: absolute;
      padding: 8px 6px;
      display: block;
      width: 90%;
      left: 2%;
      right: 2%;
      top: 30px;
      z-index: 1;
      transition: 0.3s all ease-in-out;
      color: white;
      font-weight: 700;
      text-align: center;
      font-size: 0.99rem; }
      .wallet-container .balance-cards .balance-card-background-drawer .balance-card-claim-button,
      .explorer-component .balance-cards .balance-card-background-drawer .balance-card-claim-button {
        cursor: pointer;
        background-color: white;
        display: inline-block;
        float: right;
        border-radius: 6px;
        color: black;
        padding: 2px 6px;
        font-size: 0.8rem; }
        @media screen and (max-width: 480px) {
          .wallet-container .balance-cards .balance-card-background-drawer .balance-card-claim-button,
          .explorer-component .balance-cards .balance-card-background-drawer .balance-card-claim-button {
            padding: 3px 6px; } }
        .wallet-container .balance-cards .balance-card-background-drawer .balance-card-claim-button:hover,
        .explorer-component .balance-cards .balance-card-background-drawer .balance-card-claim-button:hover {
          opacity: 0.9; }
        .wallet-container .balance-cards .balance-card-background-drawer .balance-card-claim-button:active,
        .explorer-component .balance-cards .balance-card-background-drawer .balance-card-claim-button:active {
          transform: scale(0.9); }
        .wallet-container .balance-cards .balance-card-background-drawer .balance-card-claim-button.eth,
        .explorer-component .balance-cards .balance-card-background-drawer .balance-card-claim-button.eth {
          color: #c674d7; }
        .wallet-container .balance-cards .balance-card-background-drawer .balance-card-claim-button.hodlc,
        .explorer-component .balance-cards .balance-card-background-drawer .balance-card-claim-button.hodlc {
          color: #15a4d4; }
      .wallet-container .balance-cards .balance-card-background-drawer.eth,
      .explorer-component .balance-cards .balance-card-background-drawer.eth {
        background: linear-gradient(0deg, #e169b5 0%, #ae7cf5 100%); }
      .wallet-container .balance-cards .balance-card-background-drawer.hodlc,
      .explorer-component .balance-cards .balance-card-background-drawer.hodlc {
        background: linear-gradient(0deg, #56e4d4 0%, #15a4d4 100%); }
    .wallet-container .balance-cards .balance-card-container,
    .explorer-component .balance-cards .balance-card-container {
      z-index: 2;
      padding: 3px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
      background-color: white; }
      .wallet-container .balance-cards .balance-card-container:hover .balance-card,
      .explorer-component .balance-cards .balance-card-container:hover .balance-card {
        transition: 0.2s all ease; }
      .wallet-container .balance-cards .balance-card-container .balance-card,
      .explorer-component .balance-cards .balance-card-container .balance-card {
        overflow: hidden;
        position: relative;
        height: 160px;
        width: 100%;
        padding: 20px;
        margin-bottom: 3px;
        border-radius: 15px;
        background-color: white; }
        .wallet-container .balance-cards .balance-card-container .balance-card img,
        .explorer-component .balance-cards .balance-card-container .balance-card img {
          height: 50px; }
        .wallet-container .balance-cards .balance-card-container .balance-card .top,
        .explorer-component .balance-cards .balance-card-container .balance-card .top {
          display: flex;
          flex-direction: row;
          align-items: center; }
          .wallet-container .balance-cards .balance-card-container .balance-card .top .title,
          .explorer-component .balance-cards .balance-card-container .balance-card .top .title {
            color: #282828;
            font-weight: 500;
            padding: 0 10px;
            font-size: 1.5rem;
            font-weight: 700; }
        .wallet-container .balance-cards .balance-card-container .balance-card .bottom,
        .explorer-component .balance-cards .balance-card-container .balance-card .bottom {
          margin-top: 18px; }
          .wallet-container .balance-cards .balance-card-container .balance-card .bottom .number,
          .explorer-component .balance-cards .balance-card-container .balance-card .bottom .number {
            font-size: 1.3rem;
            color: black;
            font-weight: 500;
            padding-bottom: 5px; }
            .wallet-container .balance-cards .balance-card-container .balance-card .bottom .number .fiat,
            .explorer-component .balance-cards .balance-card-container .balance-card .bottom .number .fiat {
              margin-top: 5px;
              display: inline-block;
              color: rgba(0, 0, 0, 0.5);
              font-size: 1.1rem; }
            @media screen and (max-width: 1000px) {
              .wallet-container .balance-cards .balance-card-container .balance-card .bottom .number,
              .explorer-component .balance-cards .balance-card-container .balance-card .bottom .number {
                font-size: 1.5rem;
                padding: 0.5rem 0; } }
            @media screen and (max-width: 850px) {
              .wallet-container .balance-cards .balance-card-container .balance-card .bottom .number,
              .explorer-component .balance-cards .balance-card-container .balance-card .bottom .number {
                font-size: 1.3rem;
                padding: 0.2rem 0; } }
            @media screen and (max-width: 480px) {
              .wallet-container .balance-cards .balance-card-container .balance-card .bottom .number,
              .explorer-component .balance-cards .balance-card-container .balance-card .bottom .number {
                font-size: 1rem; } }
          .wallet-container .balance-cards .balance-card-container .balance-card .bottom .t-group,
          .explorer-component .balance-cards .balance-card-container .balance-card .bottom .t-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 5px; }
            .wallet-container .balance-cards .balance-card-container .balance-card .bottom .t-group .number,
            .explorer-component .balance-cards .balance-card-container .balance-card .bottom .t-group .number {
              font-size: 1.1rem; }
            .wallet-container .balance-cards .balance-card-container .balance-card .bottom .t-group .ui.button,
            .explorer-component .balance-cards .balance-card-container .balance-card .bottom .t-group .ui.button {
              margin: 0px;
              opacity: 0.7;
              color: black;
              border-radius: 5px; }
              .wallet-container .balance-cards .balance-card-container .balance-card .bottom .t-group .ui.button:hover,
              .explorer-component .balance-cards .balance-card-container .balance-card .bottom .t-group .ui.button:hover {
                opacity: 0.8; }
              .wallet-container .balance-cards .balance-card-container .balance-card .bottom .t-group .ui.button:active,
              .explorer-component .balance-cards .balance-card-container .balance-card .bottom .t-group .ui.button:active {
                transform: scale(0.97); }
          @media screen and (max-width: 1000px) {
            .wallet-container .balance-cards .balance-card-container .balance-card .bottom,
            .explorer-component .balance-cards .balance-card-container .balance-card .bottom {
              margin-top: 30px; } }
          @media screen and (max-width: 850px) {
            .wallet-container .balance-cards .balance-card-container .balance-card .bottom,
            .explorer-component .balance-cards .balance-card-container .balance-card .bottom {
              margin-top: 20px; } }
        .wallet-container .balance-cards .balance-card-container .balance-card .logo-mark,
        .explorer-component .balance-cards .balance-card-container .balance-card .logo-mark {
          position: absolute;
          opacity: 0.3;
          top: 45px;
          right: 15px;
          height: 150px; }
          @media screen and (max-width: 1000px) {
            .wallet-container .balance-cards .balance-card-container .balance-card .logo-mark,
            .explorer-component .balance-cards .balance-card-container .balance-card .logo-mark {
              width: 200px;
              right: 14%; } }
          @media screen and (max-width: 850px) {
            .wallet-container .balance-cards .balance-card-container .balance-card .logo-mark,
            .explorer-component .balance-cards .balance-card-container .balance-card .logo-mark {
              width: 150px;
              right: 20px; } }
        @media screen and (max-width: 1000px) {
          .wallet-container .balance-cards .balance-card-container .balance-card,
          .explorer-component .balance-cards .balance-card-container .balance-card {
            width: 100%;
            height: 180px; } }
        @media screen and (max-width: 850px) {
          .wallet-container .balance-cards .balance-card-container .balance-card,
          .explorer-component .balance-cards .balance-card-container .balance-card {
            height: 160px;
            width: 100%; } }
        @media screen and (max-width: 480px) {
          .wallet-container .balance-cards .balance-card-container .balance-card,
          .explorer-component .balance-cards .balance-card-container .balance-card {
            height: 180px;
            width: 100%; } }
      .wallet-container .balance-cards .balance-card-container .btn-group,
      .explorer-component .balance-cards .balance-card-container .btn-group {
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center; }
        .wallet-container .balance-cards .balance-card-container .btn-group .ui.button,
        .explorer-component .balance-cards .balance-card-container .btn-group .ui.button {
          width: 100%;
          margin: 0px;
          opacity: 0.7;
          color: black;
          border-radius: 5px; }
          .wallet-container .balance-cards .balance-card-container .btn-group .ui.button:first-child,
          .explorer-component .balance-cards .balance-card-container .btn-group .ui.button:first-child {
            margin-right: 3px; }
          .wallet-container .balance-cards .balance-card-container .btn-group .ui.button:hover,
          .explorer-component .balance-cards .balance-card-container .btn-group .ui.button:hover {
            opacity: 0.8; }
          .wallet-container .balance-cards .balance-card-container .btn-group .ui.button:active,
          .explorer-component .balance-cards .balance-card-container .btn-group .ui.button:active {
            transform: scale(0.97); }
          .wallet-container .balance-cards .balance-card-container .btn-group .ui.button.trade-disabled,
          .wallet-container .balance-cards .balance-card-container .btn-group .ui.button .trade-disabled:active,
          .wallet-container .balance-cards .balance-card-container .btn-group .ui.button .trade-disabled:hover,
          .explorer-component .balance-cards .balance-card-container .btn-group .ui.button.trade-disabled,
          .explorer-component .balance-cards .balance-card-container .btn-group .ui.button .trade-disabled:active,
          .explorer-component .balance-cards .balance-card-container .btn-group .ui.button .trade-disabled:hover {
            opacity: 0.8;
            transform: none;
            opacity: 0.7;
            color: black; }
        @media screen and (max-width: 850px) {
          .wallet-container .balance-cards .balance-card-container .btn-group,
          .explorer-component .balance-cards .balance-card-container .btn-group {
            flex-direction: column;
            justify-content: center; }
            .wallet-container .balance-cards .balance-card-container .btn-group .ui.button:first-child,
            .explorer-component .balance-cards .balance-card-container .btn-group .ui.button:first-child {
              margin-bottom: 8px; } }
        .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button,
        .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button {
          background: rgba(0, 0, 0, 0.05); }
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:first-child,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:first-child {
            border-bottom-left-radius: 15px; }
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:last-child,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:last-child {
            border-bottom-right-radius: 15px; }
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:hover,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:hover {
            color: #2a647b;
            background: rgba(0, 0, 0, 0.05); }
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:active,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button:active {
            background: rgba(0, 0, 0, 0.01); }
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button.trade-disabled,
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button .trade-disabled:active,
          .wallet-container .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button .trade-disabled:hover,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button.trade-disabled,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button .trade-disabled:active,
          .explorer-component .balance-cards .balance-card-container .btn-group .fluid.button.wallet-action-button .trade-disabled:hover {
            background: rgba(0, 0, 0, 0.08);
            cursor: default;
            opacity: 0.45 !important;
            color: black; }
      @media screen and (max-width: 1000px) {
        .wallet-container .balance-cards .balance-card-container,
        .explorer-component .balance-cards .balance-card-container {
          flex-direction: column;
          justify-content: center; } }
    @media screen and (max-width: 1000px) {
      .wallet-container .balance-cards,
      .explorer-component .balance-cards {
        flex-direction: column;
        justify-content: center; } }
    @media screen and (max-width: 850px) {
      .wallet-container .balance-cards,
      .explorer-component .balance-cards {
        flex-direction: column;
        justify-content: center; } }
    @media screen and (max-width: 480px) {
      .wallet-container .balance-cards,
      .explorer-component .balance-cards {
        padding: 5px; } }
  .wallet-container .event-list,
  .explorer-component .event-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    padding: 0px;
    margin: auto 3px; }
    .wallet-container .event-list .event-container,
    .explorer-component .event-list .event-container {
      box-shadow: inset 0px -1px rgba(0, 0, 0, 0.05); }
      .wallet-container .event-list .event-container.event-item.no-event,
      .explorer-component .event-list .event-container.event-item.no-event {
        text-align: center;
        padding: 20px; }
      .wallet-container .event-list .event-container.event-item:last-child,
      .explorer-component .event-list .event-container.event-item:last-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px; }
      .wallet-container .event-list .event-container.event-item .column,
      .explorer-component .event-list .event-container.event-item .column {
        padding: 15px 15px; }
      .wallet-container .event-list .event-container .hash,
      .explorer-component .event-list .event-container .hash {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .wallet-container .event-list .event-container .action-label,
      .explorer-component .event-list .event-container .action-label {
        display: block;
        font-weight: 700;
        color: white;
        background: rgba(0, 0, 0, 0.05);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        border-radius: 15px;
        padding: 6px;
        margin: -2px; }
        .wallet-container .event-list .event-container .action-label.deposit,
        .explorer-component .event-list .event-container .action-label.deposit {
          color: #db58ff; }
        .wallet-container .event-list .event-container .action-label.withdraw,
        .explorer-component .event-list .event-container .action-label.withdraw {
          color: #e464b3; }
        .wallet-container .event-list .event-container .action-label.To.HODLC,
        .explorer-component .event-list .event-container .action-label.To.HODLC {
          color: #0fc7dc; }
        .wallet-container .event-list .event-container .action-label.To.HODLT,
        .explorer-component .event-list .event-container .action-label.To.HODLT {
          color: #f45a6b; }
        .wallet-container .event-list .event-container .action-label.BuyOrderFilled, .wallet-container .event-list .event-container .action-label.SellOrderOpened,
        .explorer-component .event-list .event-container .action-label.BuyOrderFilled,
        .explorer-component .event-list .event-container .action-label.SellOrderOpened {
          color: white;
          background-color: #ef1552;
          opacity: 0.6; }
        .wallet-container .event-list .event-container .action-label.SellOrderFilled, .wallet-container .event-list .event-container .action-label.BuyOrderOpened, .wallet-container .event-list .event-container .action-label.BuyFromReserve,
        .explorer-component .event-list .event-container .action-label.SellOrderFilled,
        .explorer-component .event-list .event-container .action-label.BuyOrderOpened,
        .explorer-component .event-list .event-container .action-label.BuyFromReserve {
          color: white;
          background-color: #00bfc7;
          opacity: 0.7; }
        .wallet-container .event-list .event-container .action-label.SellOrderRefunded, .wallet-container .event-list .event-container .action-label.BuyOrderRefunded, .wallet-container .event-list .event-container .action-label.SellOrderCancelled, .wallet-container .event-list .event-container .action-label.BuyOrderCancelled,
        .explorer-component .event-list .event-container .action-label.SellOrderRefunded,
        .explorer-component .event-list .event-container .action-label.BuyOrderRefunded,
        .explorer-component .event-list .event-container .action-label.SellOrderCancelled,
        .explorer-component .event-list .event-container .action-label.BuyOrderCancelled {
          color: rgba(0, 0, 0, 0.7);
          opacity: 0.8; }
      .wallet-container .event-list .event-container .status,
      .explorer-component .event-list .event-container .status {
        display: flex;
        flex-direction: row; }
        .wallet-container .event-list .event-container .status .dot,
        .explorer-component .event-list .event-container .status .dot {
          display: inline;
          margin-top: -0.4px;
          margin-right: 5px;
          font-size: 1rem;
          color: #00d087; }
      .wallet-container .event-list .event-container .sm-label,
      .explorer-component .event-list .event-container .sm-label {
        display: none;
        margin-bottom: 2px;
        font-weight: 700; }
        @media screen and (max-width: 768px) {
          .wallet-container .event-list .event-container .sm-label,
          .explorer-component .event-list .event-container .sm-label {
            display: block; } }
      .wallet-container .event-list .event-container .amount,
      .explorer-component .event-list .event-container .amount {
        text-align: right;
        line-height: 20px; }
        .wallet-container .event-list .event-container .amount .light,
        .explorer-component .event-list .event-container .amount .light {
          opacity: 0.2; }
        .wallet-container .event-list .event-container .amount .unit-span,
        .explorer-component .event-list .event-container .amount .unit-span {
          display: inline-block;
          width: 70px;
          padding-left: 20px;
          text-align: left; }
        .wallet-container .event-list .event-container .amount span,
        .explorer-component .event-list .event-container .amount span {
          font-weight: 700; }
        @media screen and (max-width: 768px) {
          .wallet-container .event-list .event-container .amount,
          .explorer-component .event-list .event-container .amount {
            text-align: left; } }
      @media screen and (max-width: 768px) {
        .wallet-container .event-list .event-container .ui.stackable.grid > .wide.column,
        .explorer-component .event-list .event-container .ui.stackable.grid > .wide.column {
          padding: 5px !important; } }
      @media screen and (max-width: 768px) {
        .wallet-container .event-list .event-container .ui.stackable.grid,
        .explorer-component .event-list .event-container .ui.stackable.grid {
          padding: 10px; } }
      @media screen and (max-width: 768px) {
        .wallet-container .event-list .event-container,
        .explorer-component .event-list .event-container {
          margin-top: 10px; } }
    .wallet-container .event-list .history-header,
    .explorer-component .event-list .history-header {
      background: white;
      font-weight: 700;
      text-transform: capitalize;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding: 15px; }
      .wallet-container .event-list .history-header .hash,
      .explorer-component .event-list .history-header .hash {
        width: 100px; }
      .wallet-container .event-list .history-header .amount,
      .explorer-component .event-list .history-header .amount {
        text-align: right; }
      @media screen and (max-width: 768px) {
        .wallet-container .event-list .history-header,
        .explorer-component .event-list .history-header {
          display: none; } }

.trade-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 20px; }
  .trade-container .container {
    padding: 20px 5px; }
  .trade-container .dashboard-session {
    padding: 10px 0px; }
    .trade-container .dashboard-session .header.session-header {
      margin-left: 10px;
      text-align: left;
      font-family: 'Open Sans', 'Roboto', sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem; }
  .trade-container .no-event {
    text-align: center;
    margin: 0;
    opacity: 0;
    color: #2a647b;
    font-weight: 600; }
    .trade-container .no-event:first-child {
      margin: auto;
      opacity: 1; }
    .trade-container .no-event .loading-component {
      margin: auto; }
    .trade-container .no-event .progress-indicator {
      margin: -20px auto 20px; }
  .trade-container .trade-info-container {
    display: flex;
    flex-direction: row;
    min-height: 183px;
    position: relative; }
    .trade-container .trade-info-container .trade-info-blocker {
      display: none;
      transition: 0.5s all ease; }
    @media (min-width: 767px) {
      .trade-container .trade-info-container:hover .trade-info-blocker.true {
        transition: 0.5s all ease;
        display: block;
        position: absolute;
        width: 99%;
        height: 100%;
        z-index: 4;
        background: rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(2px);
                backdrop-filter: blur(2px);
        border-radius: 8px;
        text-align: center; }
        .trade-container .trade-info-container:hover .trade-info-blocker.true button {
          margin: auto;
          margin-top: 60px;
          border-radius: 10px;
          font-size: 1.3rem;
          color: #2a647b; }
          .trade-container .trade-info-container:hover .trade-info-blocker.true button:hover .metamask-icon {
            transform: translateY(-2px) scale(1.2); }
          .trade-container .trade-info-container:hover .trade-info-blocker.true button .metamask-icon {
            margin-left: 5px;
            transform: translateY(-2px) scale(1); } }
    @media (max-width: 767px) {
      .trade-container .trade-info-container .trade-info-blocker.true {
        transition: 0.5s all ease;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(2px);
                backdrop-filter: blur(2px);
        border-radius: 8px;
        text-align: center; }
        .trade-container .trade-info-container .trade-info-blocker.true button {
          margin: auto;
          margin-top: 120px;
          border-radius: 10px;
          font-size: 1.3rem;
          color: #2a647b; }
          .trade-container .trade-info-container .trade-info-blocker.true button:hover .metamask-icon {
            transform: translateY(-2px) scale(1.2); }
          .trade-container .trade-info-container .trade-info-blocker.true button .metamask-icon {
            margin-left: 5px;
            transform: translateY(-2px) scale(1); } }
    @media screen and (max-width: 850px) {
      .trade-container .trade-info-container {
        flex-direction: column;
        min-height: 350px; }
        .trade-container .trade-info-container .trade-card.trade-info,
        .trade-container .trade-info-container .trade-card.trade-action {
          width: 100%;
          margin-bottom: 10px; } }
    .trade-container .trade-info-container .info-header {
      text-align: left;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem;
      padding: 0px 5px;
      margin: 0; }
    .trade-container .trade-info-container .trade-card {
      display: flex;
      flex-direction: column;
      background-color: white;
      justify-content: space-evenly;
      padding: 10px;
      width: 50%;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px; }
      .trade-container .trade-info-container .trade-card .trade-group {
        display: flex;
        flex-direction: row; }
      @media screen and (max-width: 850px) {
        .trade-container .trade-info-container .trade-card.trade-info {
          padding-top: 20px; } }
      .trade-container .trade-info-container .trade-card.trade-info .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: black;
        padding: 10px;
        padding-left: 5px;
        height: 30%;
        align-items: center; }
        @media screen and (max-width: 850px) {
          .trade-container .trade-info-container .trade-card.trade-info .info {
            flex-direction: column;
            align-items: start; }
            .trade-container .trade-info-container .trade-card.trade-info .info span {
              display: block;
              text-align: right;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis; } }
        .trade-container .trade-info-container .trade-card.trade-info .info .title {
          font-size: 1.4rem;
          font-weight: 700; }
        .trade-container .trade-info-container .trade-card.trade-info .info span {
          font-family: 'Roboto Mono' !important;
          font-size: 1.3rem; }
      .trade-container .trade-info-container .trade-card.trade-action {
        background-color: white;
        padding: 0px;
        margin-right: 10px; }
        .trade-container .trade-info-container .trade-card.trade-action .btn-group {
          margin-top: -3px; }
          @media screen and (max-width: 850px) {
            .trade-container .trade-info-container .trade-card.trade-action .btn-group {
              margin-top: 0px; } }
          .trade-container .trade-info-container .trade-card.trade-action .btn-group button {
            border-radius: 0px;
            background: rgba(0, 0, 0, 0.05); }
            .trade-container .trade-info-container .trade-card.trade-action .btn-group button:hover {
              background: rgba(0, 0, 0, 0.1); }
            .trade-container .trade-info-container .trade-card.trade-action .btn-group button:active {
              background: rgba(0, 0, 0, 0.05); }
            .trade-container .trade-info-container .trade-card.trade-action .btn-group button.true {
              opacity: 1;
              color: black;
              background-color: white;
              z-index: 3; }
            .trade-container .trade-info-container .trade-card.trade-action .btn-group button.left {
              border-top-left-radius: 15px;
              margin-right: 0px;
              color: #00bfc7; }
              .trade-container .trade-info-container .trade-card.trade-action .btn-group button.left.true {
                color: #00bfc7;
                border-top-right-radius: 15px; }
            .trade-container .trade-info-container .trade-card.trade-action .btn-group button.right {
              border-top-right-radius: 15px;
              margin-right: 0px;
              color: #ef1552; }
              .trade-container .trade-info-container .trade-card.trade-action .btn-group button.right.true {
                color: #ef1552;
                border-top-left-radius: 15px; }
        .trade-container .trade-info-container .trade-card.trade-action .trade-form {
          padding: 10px;
          background-color: white;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px; }
          .trade-container .trade-info-container .trade-card.trade-action .trade-form .max-min-btn {
            padding: 0;
            font-weight: 600;
            -webkit-user-select: none;
                    user-select: none;
            cursor: pointer;
            margin-right: 3px;
            opacity: 0.6; }
            .trade-container .trade-info-container .trade-card.trade-action .trade-form .max-min-btn:hover {
              opacity: 1;
              text-decoration: underline; }
          .trade-container .trade-info-container .trade-card.trade-action .trade-form .divider {
            font-weight: 200;
            -webkit-user-select: none;
                    user-select: none;
            cursor: pointer;
            margin-right: 3px;
            opacity: 0.6; }
          .trade-container .trade-info-container .trade-card.trade-action .trade-form .max-min-btn-devider {
            margin: 0px 5px;
            margin-right: 8px;
            width: 1px;
            background: rgba(0, 0, 0, 0.1); }
          .trade-container .trade-info-container .trade-card.trade-action .trade-form .main-input {
            border: 1px solid rgba(224, 225, 226, 0.4);
            background: rgba(0, 0, 0, 0.05);
            padding: 10px;
            border-radius: 8px;
            outline: none; }
          .trade-container .trade-info-container .trade-card.trade-action .trade-form .sub-input {
            padding: 10px 3px;
            color: black;
            font-weight: 600; }
            .trade-container .trade-info-container .trade-card.trade-action .trade-form .sub-input small {
              margin-left: 3px;
              opacity: 0.7; }
            .trade-container .trade-info-container .trade-card.trade-action .trade-form .sub-input input {
              text-align: right;
              margin-right: 5px;
              font-weight: 800;
              font-family: 'Roboto Mono' !important; }
          .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button {
            border-radius: 8px;
            color: white; }
            .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button.Buy {
              background: #00bfc7; }
              .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button.Buy:hover {
                background: #00c4cc; }
              .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button.Buy:active {
                background: #00bac2; }
            .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button.Sell {
              background: #ef1552; }
              .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button.Sell:hover {
                background: #f12d64; }
              .trade-container .trade-info-container .trade-card.trade-action .trade-form .trade-action-button.Sell:active {
                background: #db0f48; }
  .trade-container .order-book {
    height: 100%; }
    .trade-container .order-book .menu {
      cursor: pointer; }
    .trade-container .order-book.order-switcher {
      padding-top: 20px; }
      .trade-container .order-book.order-switcher .item {
        font-weight: 700;
        padding: 10px 20px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 100px;
        margin: 0px 5px; }
        .trade-container .order-book.order-switcher .item:hover {
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.1); }
        .trade-container .order-book.order-switcher .item:active {
          transform: scale(0.97); }
        .trade-container .order-book.order-switcher .item.active {
          background: #2a647b;
          color: white;
          border-radius: 100px; }
          .trade-container .order-book.order-switcher .item.active:hover {
            color: white;
            background: #2a647b; }
    .trade-container .order-book .orders-container {
      border-radius: 15px;
      background: white;
      box-shadow: inset rgba(0, 0, 0, 0.01) 0px 0px 1px; }
      .trade-container .order-book .orders-container .order-section {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        padding: 15px 0px 50px; }
        @media screen and (max-width: 850px) {
          .trade-container .order-book .orders-container .order-section {
            flex-direction: column; } }
        .trade-container .order-book .orders-container .order-section .book {
          width: 100%;
          padding: 0px; }
          @media screen and (max-width: 850px) {
            .trade-container .order-book .orders-container .order-section .book {
              width: 100%; } }
          .trade-container .order-book .orders-container .order-section .book .info-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px 10px 10px; }
            @media screen and (max-width: 850px) {
              .trade-container .order-book .orders-container .order-section .book .info-container {
                flex-direction: column;
                align-items: start; } }
            .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector {
              font-weight: 700;
              display: flex; }
              @media screen and (max-width: 850px) {
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector {
                  flex-direction: column;
                  align-items: center;
                  width: 100%;
                  padding-top: 5px; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select {
                    width: 100%; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector .button-group-container {
                    width: 100%;
                    justify-content: space-between; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button:first-child {
                    margin-left: 0px; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button:last-child {
                    margin-right: 0px; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button,
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator {
                    flex-grow: 1; } }
              .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector .button-group-container {
                display: flex; }
              .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button.prev span {
                display: inline-block;
                transform: rotate(30deg) translateY(-1px); }
              .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button.next span {
                display: inline-block;
                transform: rotate(330deg) translateY(-1px); }
              .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select,
              .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button,
              .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator {
                -webkit-user-select: none;
                        user-select: none;
                align-items: stretch;
                display: inline-block;
                border-radius: 5px;
                padding: 2px 10px;
                margin: 2px;
                border: none;
                background: rgba(0, 0, 0, 0.1);
                font-weight: 700;
                opacity: 0.7;
                cursor: pointer;
                outline: none;
                height: 25px; }
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select:hover,
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button:hover,
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator:hover {
                  opacity: 1; }
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select:disabled,
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button:disabled,
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator:disabled {
                  opacity: 0.5; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select:disabled:hover,
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button:disabled:hover,
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator:disabled:hover {
                    cursor: wait;
                    opacity: 0.5; }
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select.disabled-true,
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button.disabled-true,
                .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator.disabled-true {
                  opacity: 0.5; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector select.disabled-true:hover,
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector button.disabled-true:hover,
                  .trade-container .order-book .orders-container .order-section .book .info-container .limit-selector span.pagination-indicator.disabled-true:hover {
                    cursor: default !important; }
            .trade-container .order-book .orders-container .order-section .book .info-container .order-count {
              text-align: left;
              padding-left: 10px;
              display: flex;
              flex-direction: row; }
              .trade-container .order-book .orders-container .order-section .book .info-container .order-count .last-updated-indicator {
                opacity: 0.6;
                margin: 0px 5px;
                display: flex;
                align-items: center; }
                .trade-container .order-book .orders-container .order-section .book .info-container .order-count .last-updated-indicator .text {
                  margin: 0px 5px;
                  font-size: 0.9rem; }
                .trade-container .order-book .orders-container .order-section .book .info-container .order-count .last-updated-indicator img {
                  height: 17px; }
                  .trade-container .order-book .orders-container .order-section .book .info-container .order-count .last-updated-indicator img.spin-0 {
                    animation-name: spin;
                    animation-duration: 1000ms;
                    animation-timing-function: linear; }
          .trade-container .order-book .orders-container .order-section .book .list-component {
            display: block; }
          .trade-container .order-book .orders-container .order-section .book .order {
            -webkit-user-select: none;
                    user-select: none;
            margin: 0px 0px;
            border-radius: 0px;
            padding: 10px 20px;
            position: relative;
            overflow: hidden;
            box-shadow: inset 0px -1px rgba(0, 0, 0, 0.05); }
            @media screen and (max-width: 850px) {
              .trade-container .order-book .orders-container .order-section .book .order {
                padding: 15px; } }
            .trade-container .order-book .orders-container .order-section .book .order .price-row .price {
              font-weight: 500; }
            .trade-container .order-book .orders-container .order-section .book .order .wide.column {
              font-size: 1.1rem; }
              @media screen and (max-width: 850px) {
                .trade-container .order-book .orders-container .order-section .book .order .wide.column {
                  padding: 5px !important; } }
              .trade-container .order-book .orders-container .order-section .book .order .wide.column.cancel-column {
                display: flex;
                justify-content: center;
                align-items: center; }
            .trade-container .order-book .orders-container .order-section .book .order .action.false .cancel-your-order-btn {
              display: none; }
            .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn {
              text-transform: uppercase;
              padding: 6px 15px;
              margin: 0px;
              font-size: 0.8rem;
              transform: translateY(-1px);
              border-radius: 8px; }
              @media screen and (max-width: 768px) {
                .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn {
                  font-size: 1rem;
                  width: 100%;
                  display: block;
                  padding: 10px 10px;
                  color: white;
                  background-color: #ef1552;
                  opacity: 0.9; } }
              .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn:hover {
                color: white;
                background-color: #ef1552;
                transform: scale(0.95); }
              .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn:active {
                transform: scale(0.9); }
              .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn.disable-cancel,
              .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn .disable-cancel:hover,
              .trade-container .order-book .orders-container .order-section .book .order .action.true .cancel-your-order-btn .disable-cancel:active {
                transform: none;
                background-color: #e0e1e2;
                color: rgba(0, 0, 0, 0.6);
                opacity: 0.45 !important;
                cursor: default; }
            .trade-container .order-book .orders-container .order-section .book .order .amount {
              line-height: 20px; }
              .trade-container .order-book .orders-container .order-section .book .order .amount .light {
                opacity: 0.2; }
              .trade-container .order-book .orders-container .order-section .book .order .amount .unit-span {
                display: inline-block;
                width: 70px;
                padding-left: 20px;
                text-align: left; }
              .trade-container .order-book .orders-container .order-section .book .order .amount span {
                font-weight: 700; }
              @media screen and (max-width: 768px) {
                .trade-container .order-book .orders-container .order-section .book .order .amount {
                  text-align: left; } }
            .trade-container .order-book .orders-container .order-section .book .order div {
              text-overflow: ellipsis;
              overflow: hidden; }
            .trade-container .order-book .orders-container .order-section .book .order .sm-label {
              display: none;
              margin-bottom: 2px;
              font-weight: 700; }
              @media screen and (max-width: 768px) {
                .trade-container .order-book .orders-container .order-section .book .order .sm-label {
                  display: block; } }
            .trade-container .order-book .orders-container .order-section .book .order .order-id {
              text-transform: capitalize; }
            .trade-container .order-book .orders-container .order-section .book .order .user {
              text-transform: capitalize; }
            .trade-container .order-book .orders-container .order-section .book .order .amount {
              text-transform: capitalize; }
            .trade-container .order-book .orders-container .order-section .book .order .price {
              text-transform: capitalize; }
            .trade-container .order-book .orders-container .order-section .book .order.order-header {
              font-weight: bold;
              background: none; }
              @media screen and (max-width: 768px) {
                .trade-container .order-book .orders-container .order-section .book .order.order-header {
                  display: none; } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.loading-component {
  width: 100%;
  text-align: center;
  padding: 50px; }
  .loading-component .sk-chase {
    width: 40px;
    height: 40px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
    margin: auto; }
  .loading-component .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both; }
  .loading-component .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #2a647b;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both; }
  .loading-component .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s; }
  .loading-component .sk-chase-dot:nth-child(2) {
    animation-delay: -1s; }
  .loading-component .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s; }
  .loading-component .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s; }
  .loading-component .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s; }
  .loading-component .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s; }
  .loading-component .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s; }
  .loading-component .sk-chase-dot:nth-child(2):before {
    animation-delay: -1s; }
  .loading-component .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s; }
  .loading-component .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s; }
  .loading-component .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s; }
  .loading-component .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s; }

@keyframes sk-chase {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); }
  100%,
  0% {
    transform: scale(1); } }

.loading-component.navbar {
  text-align: center;
  padding: 2px; }
  .loading-component.navbar .sk-chase {
    width: 11px;
    height: 11px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
    margin: auto; }
  .loading-component.navbar .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both; }
  .loading-component.navbar .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: white;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both; }
  .loading-component.navbar .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s; }
  .loading-component.navbar .sk-chase-dot:nth-child(2) {
    animation-delay: -1s; }
  .loading-component.navbar .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s; }
  .loading-component.navbar .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s; }
  .loading-component.navbar .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s; }
  .loading-component.navbar .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s; }
  .loading-component.navbar .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s; }
  .loading-component.navbar .sk-chase-dot:nth-child(2):before {
    animation-delay: -1s; }
  .loading-component.navbar .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s; }
  .loading-component.navbar .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s; }
  .loading-component.navbar .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s; }
  .loading-component.navbar .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s; }

@keyframes sk-chase {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); }
  100%,
  0% {
    transform: scale(1); } }

.ui.dimmer.modals.visible.active {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px); }

.ui.small.modal.visible.active.main-modal {
  height: auto !important;
  width: 40vw !important;
  margin: 20vh 30vw !important;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
  border-radius: 15px; }
  @media screen and (max-width: 850px) {
    .ui.small.modal.visible.active.main-modal {
      width: 90vw !important;
      margin: 10vh 5vw !important; } }
  .ui.small.modal.visible.active.main-modal.main-modal-wide {
    width: 60vw !important;
    margin: 20vh 20vw !important; }
    @media screen and (max-width: 850px) {
      .ui.small.modal.visible.active.main-modal.main-modal-wide {
        width: 90vw !important;
        margin: 10vh 5vw !important; } }
  .ui.small.modal.visible.active.main-modal .modal-body {
    padding: 40px 20px 20px; }
  .ui.small.modal.visible.active.main-modal .equivalent-container .sub-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 3px;
    color: black;
    font-weight: 600; }
    .ui.small.modal.visible.active.main-modal .equivalent-container .sub-input small {
      margin-left: 3px;
      opacity: 0.7; }
    .ui.small.modal.visible.active.main-modal .equivalent-container .sub-input input {
      width: 60px;
      text-align: right;
      margin-right: 5px;
      font-weight: 800;
      font-family: 'Roboto Mono' !important; }
  .ui.small.modal.visible.active.main-modal .modal-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px auto 0px; }
    .ui.small.modal.visible.active.main-modal .modal-button-group .bg-modal-button {
      margin-right: 0px;
      border-radius: 10px;
      font-size: 1.3rem;
      opacity: 0.8; }
      .ui.small.modal.visible.active.main-modal .modal-button-group .bg-modal-button.highlight {
        color: #2a647b; }
      .ui.small.modal.visible.active.main-modal .modal-button-group .bg-modal-button.cancel {
        opacity: 0.5; }
      .ui.small.modal.visible.active.main-modal .modal-button-group .bg-modal-button:hover {
        opacity: 0.9; }
        .ui.small.modal.visible.active.main-modal .modal-button-group .bg-modal-button:hover .metamask-icon {
          transform: translateY(-2px) scale(1.2); }
      .ui.small.modal.visible.active.main-modal .modal-button-group .bg-modal-button .metamask-icon {
        margin-left: 5px;
        transform: translateY(-2px) scale(1); }
  .ui.small.modal.visible.active.main-modal .modal-title {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: black;
    box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.5);
    color: white;
    background-color: #2a647b; }
  .ui.small.modal.visible.active.main-modal input.modal-input {
    display: block;
    font-family: 'Roboto Mono' !important;
    font-size: 1.4rem;
    width: 100%;
    padding: 15px 15px;
    outline: none;
    border-radius: 8px;
    border: none;
    background: rgba(0, 0, 0, 0.04); }
    .ui.small.modal.visible.active.main-modal input.modal-input:hover {
      background: rgba(0, 0, 0, 0.06); }
  .ui.small.modal.visible.active.main-modal .top-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px; }
    .ui.small.modal.visible.active.main-modal .top-group .input-label {
      font-size: 1.3rem;
      font-weight: 500;
      display: block;
      width: 100%;
      text-align: left; }
    .ui.small.modal.visible.active.main-modal .top-group .btn.max-btn {
      font-weight: bold;
      background-color: rgba(49, 131, 137, 0.02);
      border-radius: 8px; }
      .ui.small.modal.visible.active.main-modal .top-group .btn.max-btn:hover {
        background-color: #2a647b;
        color: white;
        transition: 0.1s all; }
      .ui.small.modal.visible.active.main-modal .top-group .btn.max-btn:focus {
        outline: none;
        box-shadow: none; }

.ui.small.modal.visible.active.main-modal.connect-modal {
  border-radius: 15px; }
  .ui.small.modal.visible.active.main-modal.connect-modal .modal-body {
    padding: 40px 35px 30px; }
  .ui.small.modal.visible.active.main-modal.connect-modal .connect-group {
    display: flex;
    flex-direction: column; }
    .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item {
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 15px 15px;
      border-radius: 12px;
      margin: 5px;
      font-weight: 600;
      font-size: 1.1rem;
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none;
      border: 2px solid #edeef2; }
      .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item .connected {
        display: none; }
      .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item .left-item-container {
        display: flex;
        align-items: center;
        flex-direction: row; }
      .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item.true {
        background-color: rgba(0, 191, 199, 0.08);
        border-color: rgba(0, 191, 199, 0.08); }
        .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item.true .title {
          color: #2a647b; }
        .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item.true .connected {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          background-color: #00bfc7;
          margin-right: 10px; }
      .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item:hover {
        border-color: #2a647b; }
      .ui.small.modal.visible.active.main-modal.connect-modal .connect-group .item:active {
        transform: scale(0.98); }

.banner-container.disable-banner .body {
  background-color: #ff3c00; }
  .banner-container.disable-banner .body .header > .tag {
    color: #ff3c00; }
  .banner-container.disable-banner .body .content {
    padding: 0px 10px 3px; }

.banner-container .body {
  padding: 15px;
  margin: 5px;
  background-color: #2a647b;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 50px -10px rgba(0, 0, 0, 0.125); }
  .banner-container .body .header {
    padding: 0px 5px 3px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .banner-container .body .header {
        display: block; } }
    .banner-container .body .header .title {
      font-size: 1.2rem;
      display: inline-block;
      padding-left: 5px; }
      @media screen and (max-width: 768px) {
        .banner-container .body .header .title {
          display: block;
          padding-left: 0px; } }
    .banner-container .body .header .tag {
      display: inline-block;
      font-size: 0.7rem;
      font-weight: 800;
      letter-spacing: 1px;
      background-color: white;
      color: #2a647b;
      padding: 4px 10px;
      transform: translateY(-2px);
      opacity: 0.8; }
      @media screen and (max-width: 768px) {
        .banner-container .body .header .tag {
          display: inline-block; } }
  .banner-container .body a.item,
  .banner-container .body a.item:visited {
    border-radius: 6px;
    border: 2px solid white;
    padding: 4px 12px;
    margin: 3px;
    color: white;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600; }
    .banner-container .body a.item:hover,
    .banner-container .body a.item:visited:hover {
      text-decoration: none;
      background-color: white;
      color: #2a647b;
      transition: 0.5s all ease; }
    @media screen and (max-width: 768px) {
      .banner-container .body a.item,
      .banner-container .body a.item:visited {
        padding: 2px 5px;
        font-size: 0.8rem; } }

@keyframes blinker {
  50% {
    background-color: rgba(255, 255, 255, 0.8);
    transform: scale(1.1) translateY(-1px); } }

.join-modal .join-modal-inner {
  padding: 1rem; }
  .join-modal .join-modal-inner .social-link-title {
    font-size: 24px;
    font-weight: 600; }
  .join-modal .join-modal-inner .social-link-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0; }
    .join-modal .join-modal-inner .social-link-container .social-link {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #2a647b;
      border-radius: 8px;
      width: 100%;
      padding: 15px;
      background-color: #f6f6f6;
      margin-top: 10px; }
      .join-modal .join-modal-inner .social-link-container .social-link:hover {
        text-decoration: none;
        background-color: #e7e7e7; }

.txn-detail-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 20px; }
  .txn-detail-container .container {
    padding: 20px 5px; }
  .txn-detail-container .dashboard-session {
    padding: 10px 0px; }
    .txn-detail-container .dashboard-session .header.session-header {
      margin-left: 10px;
      text-align: left;
      font-family: 'Open Sans', 'Roboto', sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem; }

.data-container {
  background-color: white;
  box-shadow: inset 0px -1px rgba(0, 0, 0, 0.05); }
  .data-container .item {
    display: flex;
    flex-direction: row;
    padding: 15px;
    box-shadow: inset 0px -1px rgba(0, 0, 0, 0.05); }
    .data-container .item .key {
      width: 30%; }
    .data-container .item .value {
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }

.back-btn {
  cursor: pointer; }

*,
body {
  font-family: 'Open Sans', 'Roboto', sans-serif !important; }
  * a,
  body a {
    color: #2a647b; }

body {
  background-color: rgba(49, 131, 137, 0.02); }

.non-mobile-only {
  display: inline-block; }
  @media screen and (max-width: 480px) {
    .non-mobile-only {
      display: none; } }

.mobile-only {
  display: none; }
  @media screen and (max-width: 480px) {
    .mobile-only {
      display: inline-block; } }

.upgrade {
  width: 100%;
  height: 90%;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  padding: 30px 20px;
  margin: 10px 0; }
  .upgrade .title {
    margin: 10px;
    font-size: 1.2rem;
    font-weight: 700; }
  .upgrade .btn.upgrade-btn {
    margin: auto;
    margin-top: 20px;
    border-radius: 8px;
    font-size: 1.3rem;
    background-color: #3a2bac;
    color: white;
    outline: none;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.25); }
    .upgrade .btn.upgrade-btn:hover {
      background-color: #2a647b;
      color: white;
      transition: 0.1s all;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5); }
    .upgrade .btn.upgrade-btn:focus {
      outline: none;
      box-shadow: none; }

.dimmer-2 {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
  will-change: opacity;
  z-index: 1000; }
  @media screen and (max-width: 480px) {
    .dimmer-2 {
      padding: 2em 0; } }

.cus-modal {
  max-width: 800px;
  border-radius: 8px;
  position: relative; }
  .cus-modal.primary > .title-group {
    background-color: #2a647b;
    color: white; }
  .cus-modal.error > .title-group {
    background-color: #ef1552;
    color: white; }
  .cus-modal .title-group {
    position: absolute;
    top: -42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    max-height: 42px;
    height: 42px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 1px -1px 10px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3); }
    @media screen and (max-width: 480px) {
      .cus-modal .title-group {
        padding: 20px 0; } }
    .cus-modal .title-group .modal-title {
      font-size: 1.3rem;
      line-height: 20px;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase; }
    .cus-modal .title-group .close-icon {
      position: absolute;
      right: 10px; }
  .cus-modal.sm {
    min-width: 445px; }
    @media screen and (max-width: 850px) {
      .cus-modal.sm {
        max-width: 90vw;
        min-width: 345px; } }
    @media screen and (max-width: 480px) {
      .cus-modal.sm {
        max-width: 90vw;
        min-height: 30vh;
        min-width: 86vw; } }
  .cus-modal.md {
    min-width: 33vw; }
    @media screen and (max-width: 480px) {
      .cus-modal.md {
        min-height: 30vh;
        max-width: 90vw;
        min-width: 80vw; } }
  .cus-modal .modal-inner-content {
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
    padding: 2rem;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 70vh;
    width: 100%;
    overflow-y: auto; }
    .cus-modal .modal-inner-content::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 20px; }
    .cus-modal .modal-inner-content::-webkit-scrollbar-thumb {
      opacity: 0;
      cursor: pointer; }
    @media screen and (max-width: 480px) {
      .cus-modal .modal-inner-content {
        height: 100%; } }
  .cus-modal .button-group {
    margin: 30px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 480px) {
      .cus-modal .button-group .btn.md {
        width: 45%; } }

.btn {
  border: none;
  background-color: #f6f6f6;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 38px;
  margin: 0 5px;
  line-height: 18px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700; }
  @media screen and (max-width: 850px) {
    .btn.cancel-psd-btn {
      margin-top: 5px; } }
  .btn:hover {
    background-color: white; }
  .btn.primary {
    background-color: #2a647b;
    cursor: pointer; }
    .btn.primary:hover {
      background-color: #245568; }
    .btn.primary:disabled {
      background-color: #70b3ce; }
      .btn.primary:disabled:hover {
        background-color: #70b3ce; }
  .btn.secondary {
    background-color: #e0e1e2;
    color: #2a647b; }
    .btn.secondary:hover {
      background-color: #d3d4d6; }
    .btn.secondary:disabled {
      background-color: #e0e1e2; }
      .btn.secondary:disabled:hover {
        background-color: #e0e1e2; }
  .btn.grey {
    background-color: #e0e1e2;
    color: #9ea1a4; }
    .btn.grey:hover {
      background-color: #d3d4d6; }
    .btn.grey:disabled {
      background-color: #e0e1e2; }
      .btn.grey:disabled:hover {
        background-color: #e0e1e2; }
  .btn.right {
    float: right; }
  .btn.sm {
    font-size: 15px;
    padding: 8px 10px;
    min-width: 80px;
    max-height: 35px; }
  .btn.md {
    font-size: 1.3rem;
    padding: 1.8rem 1rem;
    min-width: 120px;
    max-height: 38px;
    line-height: 1em; }
  .btn.lg {
    font-size: 20px;
    max-width: 100%;
    padding: 15px 20px;
    min-width: 300px;
    max-height: 50px; }
  @media screen and (max-width: 850px) {
    .btn.md, .btn.lg {
      width: 100%;
      min-width: 0; } }
  @media screen and (max-width: 480px) {
    .btn {
      margin: 0; } }
  .btn .loading {
    border: 3px solid #f3f3f3;
    /* Light grey */
    border-top: 3px solid #d1d1d1;
    /* Blue */
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin: 0 auto;
    animation: spin 0.5s linear infinite; }
  .btn:focus {
    outline: none; }
  .btn:disabled {
    cursor: default;
    background-color: #d7d7d7; }
    .btn:disabled:hover {
      background-color: #d7d7d7; }

.event-list-component .ui.header.session-header {
  display: inline-block;
  margin-bottom: 20px; }

.event-list-component .true-user-address {
  opacity: 0.4;
  -webkit-text-decoration: underline dotted #2a647b;
          text-decoration: underline dotted #2a647b; }

.event-list-component .time-selector {
  display: inline-block; }
  .event-list-component .time-selector select {
    display: inline-block;
    border-radius: 5px;
    padding: 2px 5px;
    margin-left: 10px;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    font-weight: 700;
    opacity: 0.7;
    cursor: pointer;
    outline: none; }
    .event-list-component .time-selector select:hover {
      opacity: 1; }
  @media (max-width: 767px) {
    .event-list-component .time-selector {
      margin-bottom: 10px; } }

.calulator-component {
  margin-top: 15px;
  max-width: 900px; }
  .calulator-component .session-body {
    padding: 55px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 50px -10px rgba(107, 87, 87, 0.125); }
    @media (max-width: 767px) {
      .calulator-component .session-body {
        padding: 20px; } }
    .calulator-component .session-body .session-header {
      margin-left: 0px; }
    .calulator-component .session-body .info-session {
      padding-bottom: 50px;
      font-size: 1.2rem; }
      .calulator-component .session-body .info-session .result-text {
        margin-top: 30px;
        font-size: 1.4rem; }
    .calulator-component .session-body form .btn.simulate-btn {
      font-weight: bold;
      color: white;
      background-color: #2a647b;
      border-radius: 100px; }
      .calulator-component .session-body form .btn.simulate-btn:hover {
        background-color: #3783a1;
        color: white;
        transition: 0.1s all; }
      .calulator-component .session-body form .btn.simulate-btn:focus {
        outline: none;
        box-shadow: none; }
    .calulator-component .session-body form select {
      display: inline-block;
      border-radius: 6px;
      padding: 5px 15px;
      background: rgba(0, 0, 0, 0);
      border: 2px solid rgba(0, 0, 0, 0.1);
      font-weight: 700;
      cursor: pointer;
      outline: none;
      margin: 5px;
      height: 40px; }
      .calulator-component .session-body form select:hover {
        outline: none;
        border: 2px solid rgba(0, 0, 0, 0.4); }
      .calulator-component .session-body form select:active, .calulator-component .session-body form select:focus {
        outline: none;
        border: 2px solid #2a647b; }
    .calulator-component .session-body form input {
      border-radius: 6px;
      margin: 5px;
      border: 2px solid rgba(0, 0, 0, 0.2);
      padding: 5px 5px 5px 15px;
      display: inline-block;
      width: 125px;
      height: 40px; }
      .calulator-component .session-body form input:hover {
        outline: none;
        border: 2px solid rgba(0, 0, 0, 0.4); }
      .calulator-component .session-body form input:active, .calulator-component .session-body form input:focus {
        outline: none;
        border: 2px solid #2a647b; }
    @media (max-width: 767px) {
      .calulator-component .session-body form {
        margin-bottom: 10px; } }
    .calulator-component .session-body form small.notes {
      background: rgba(42, 100, 123, 0.2);
      box-shadow: inset 0px 0px 0px 2px rgba(42, 100, 123, 0.6);
      color: #2a647b;
      padding: 5px 10px;
      border-radius: 5px;
      display: inline-block;
      margin: 5px 0px; }
    .calulator-component .session-body .chart-container {
      width: 100%;
      height: 500px; }
      .calulator-component .session-body .chart-container .recharts-wrapper {
        border-radius: 8px; }
      .calulator-component .session-body .chart-container .recharts-legend-wrapper {
        bottom: -30px; }
      .calulator-component .session-body .chart-container .recharts-surface {
        margin-left: -20px; }
      .calulator-component .session-body .chart-container .recharts-legend-item.legend-item-0 {
        transform: translateX(-30px); }

.calculator-type-switcher {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: inline-flex;
  width: auto;
  margin: 5px auto 20px; }
  @media screen and (max-width: 850px) {
    .calculator-type-switcher {
      display: flex; } }
  .calculator-type-switcher a.item {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    padding: 6px 30px;
    font-weight: 700;
    text-transform: capitalize; }
    @media screen and (max-width: 850px) {
      .calculator-type-switcher a.item {
        width: 50%;
        text-align: center;
        padding: 6px 15px; } }
    .calculator-type-switcher a.item:hover {
      color: #2a647b;
      background: rgba(0, 0, 0, 0.08); }
    .calculator-type-switcher a.item:active {
      background: rgba(0, 0, 0, 0.05); }
    .calculator-type-switcher a.item.hodling {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px; }
    .calculator-type-switcher a.item.cashout {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px; }
    .calculator-type-switcher a.item.true {
      transition: 0.1s all ease-in-out;
      background: #2a647b;
      color: white; }
      .calculator-type-switcher a.item.true:hover {
        background: rgba(42, 100, 123, 0.95); }

.disclaimer-paragraph {
  text-align: justify;
  opacity: 0.8;
  font-size: 1.1rem;
  line-height: 1.6rem; }

.graph-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }
  .graph-component .container {
    padding: 30px 5px; }
    .graph-component .container .session-body {
      padding: 55px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 1px 50px -10px rgba(107, 87, 87, 0.125); }
      @media (max-width: 767px) {
        .graph-component .container .session-body {
          padding: 20px; } }
    .graph-component .container .header.session-header {
      display: inline-block;
      margin-bottom: 20px;
      font-size: 1.1rem; }

.time-selector.explorer.graph {
  margin: 0 10px; }

