.txn-detail-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  .container {
    padding: 20px 5px;
  }
  .dashboard-session {
    padding: 10px 0px;
    .header.session-header {
      margin-left: 10px;
      text-align: left;
      font-family: 'Open Sans', 'Roboto', sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem;
    }
  }
}

.data-container {
  background-color: white;
  box-shadow: inset 0px -1px rgba(black, 0.05);
  .item {
    display: flex;
    flex-direction: row;
    padding: 15px;
    box-shadow: inset 0px -1px rgba(black, 0.05);
    .key {
      width: 30%;
    }
    .value {
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.back-btn {
  cursor: pointer;
}
