@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');

$breakpoints: (
  x-small: 330px,
  small: 480px,
  medium: 850px,
  large: 1024px,
  x-large: 1130px
);
@import './variable.scss';
@import './width.scss';
@import './nav.scss';
@import './dashboard.scss';
@import './explorer.scss';
@import './wallet.scss';
@import './trade.scss';
@import './loader.scss';
@import './modal.scss';
@import './profile.scss';
@import './banner.scss';
@import './join-us.scss';
@import './txnDetail.scss';
*,
body {
  font-family: 'Open Sans', 'Roboto', sans-serif !important;
  a {
    // color: rgb(52, 152, 219);
    color: $main-color;
    // &:hover{
    //   // color: darken(rgb(52, 152, 219), 10%);
    // }
  }
}

body {
  background-color: $background-color;
}

.non-mobile-only {
  display: inline-block;
  @media screen and (max-width: map-get($breakpoints, small)) {
    display: none;
  }
}
.mobile-only {
  display: none;
  @media screen and (max-width: map-get($breakpoints, small)) {
    display: inline-block;
  }
}

.upgrade {
  width: 100%;
  height: 90%;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  padding: 30px 20px;
  margin: 10px 0;
  .title {
    margin: 10px;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .btn.upgrade-btn {
    margin: auto;
    margin-top: 20px;
    border-radius: 8px;
    font-size: 1.3rem;
    background-color: #3a2bac;
    color: white;
    outline: none;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.25);
    &:hover {
      background-color: $main-color;
      color: white;
      transition: 0.1s all;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5);
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.dimmer-2 {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 2em 0;
  }
}

.cus-modal {
  max-width: 800px;
  border-radius: 8px;
  position: relative;
  &.primary > .title-group {
    background-color: $main-color;
    color: white;
  }
  &.error > .title-group {
    background-color: $red-color;
    color: white;
  }
  .title-group {
    position: absolute;
    top: -42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    max-height: 42px;
    height: 42px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 1px -1px 10px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 20px 0;
    }
    .modal-title {
      font-size: 1.3rem;
      line-height: 20px;
      letter-spacing: 1px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .close-icon {
      position: absolute;
      right: 10px;
    }
  }
  &.sm {
    min-width: 445px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      max-width: 90vw;
      min-width: 345px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      max-width: 90vw;
      min-height: 30vh;
      min-width: 86vw;
    }
  }
  &.md {
    min-width: 33vw;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 30vh;
      max-width: 90vw;
      min-width: 80vw;
    }
  }
  .modal-inner-content {
    box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.3);
    padding: 2rem;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 70vh;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      //full track
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      //bar
      opacity: 0;
      cursor: pointer;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      height: 100%;
    }
  }
  .button-group {
    margin: 30px auto 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn.md {
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 45%;
      }
    }
  }
}

.btn {
  border: none;
  background-color: #f6f6f6;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 38px;
  margin: 0 5px;
  line-height: 18px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  &.cancel-psd-btn {
    @media screen and (max-width: map-get($breakpoints, medium)) {
      margin-top: 5px;
    }
  }
  &:hover {
    background-color: lighten(#f6f6f6, 5%);
  }
  &.primary {
    background-color: $main-color;
    cursor: pointer;
    &:hover {
      background-color: darken($main-color, 5%);
    }
    &:disabled {
      background-color: lighten($main-color, 30%);
      &:hover {
        background-color: lighten($main-color, 30%);
      }
    }
  }
  &.secondary {
    background-color: #e0e1e2;
    color: $main-color;
    &:hover {
      background-color: darken(#e0e1e2, 5%);
    }
    &:disabled {
      background-color: #e0e1e2;
      &:hover {
        background-color: #e0e1e2;
      }
    }
  }
  &.grey {
    background-color: #e0e1e2;
    color: darken(#e0e1e2, 25%);
    &:hover {
      background-color: darken(#e0e1e2, 5%);
    }
    &:disabled {
      background-color: #e0e1e2;
      &:hover {
        background-color: #e0e1e2;
      }
    }
  }
  &.right {
    float: right;
  }
  &.sm {
    font-size: 15px;
    padding: 8px 10px;
    min-width: 80px;
    max-height: 35px;
  }
  &.md {
    font-size: 1.3rem;
    padding: 1.8rem 1rem;
    min-width: 120px;
    max-height: 38px;
    line-height: 1em;
  }
  &.lg {
    font-size: 20px;
    max-width: 100%;
    padding: 15px 20px;
    min-width: 300px;
    max-height: 50px;
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    &.md,
    &.lg {
      width: 100%;
      min-width: 0;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin: 0;
  }
  .loading {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #d1d1d1; /* Blue */
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin: 0 auto;
    animation: spin 0.5s linear infinite;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default;
    background-color: lighten(#a3a4a5, 20%);
    &:hover {
      background-color: lighten(#a3a4a5, 20%);
    }
  }
}
