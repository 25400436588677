@import '../../assets/scss/variable.scss';

.dashboard-market-info {
  display: none;
  @media screen and (max-width: map-get($breakpoints, large)) {
    display: inline-block;
    width: 100%;
  }
  .info-card-group {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    .info-card {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      width: 100%;
      //box-shadow: 0px 1px 50px -10px rgba(0, 0, 0, 0.125);
      margin: 5px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
      span.price-percentage-indicator {
        font-weight: 700;
        font-size: 1rem;
        color: rgb(23, 196, 16);
        padding: 0px 3px;
      }
      span.price-percentage-indicator-length {
        font-size: 0.7rem;
        color: black;
        background-color: rgba(black, 0.05);
        border-radius: 15px;
        padding: 1px 5px;
      }
    }
    @media screen and (max-width: map-get($breakpoints, large)) {
      display: flex;
    }
  }
}
.wallet-container,
.explorer-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .container {
    padding: 20px 5px;
  }
  .dashboard-session {
    padding: 10px 0px;
    .header.session-header {
      margin-left: 10px;
      text-align: left;
      font-family: 'Open Sans', 'Roboto', sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem;
    }
  }
  .balance-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    .balance-card-outter-container {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 5px;
      width: 100%;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        padding: 0px;
        padding-bottom: 10px;
      }
      &.drawer-open-true {
        transition: 0.1s all ease-in-out;
        margin-top: 30px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          margin-top: 40px;
        }
        &:hover {
          .balance-card-background-drawer {
            top: -33px;
          }
        }
        .balance-card-container {
          box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px,
            rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
            rgba(0, 0, 0, 0.01) 0px 24px 32px;
        }
        .balance-card-background-drawer {
          top: -25px;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            top: -33px;
          }
        }
      }
    }

    .balance-card-background-drawer {
      background-color: white;
      margin: auto;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      position: absolute;
      padding: 8px 6px;
      display: block;
      width: 90%;
      left: 2%;
      right: 2%;
      top: 30px;
      z-index: 1;
      transition: 0.3s all ease-in-out;
      color: white;
      font-weight: 700;
      text-align: center;
      font-size: 0.99rem;
      .balance-card-claim-button {
        cursor: pointer;
        background-color: white;
        display: inline-block;
        float: right;
        border-radius: 6px;
        color: black;
        padding: 2px 6px;
        font-size: 0.8rem;
        @media screen and (max-width: map-get($breakpoints, small)) {
          padding: 3px 6px;
        }
        &:hover {
          opacity: 0.9;
        }
        &:active {
          transform: scale(0.9);
        }
        &.eth {
          color: #c674d7;
        }
        &.hodlc {
          color: #15a4d4;
        }
      }
      &.eth {
        background: linear-gradient(0deg, #e169b5 0%, #ae7cf5 100%);
      }
      &.hodlc {
        background: linear-gradient(0deg, #56e4d4 0%, #15a4d4 100%);
      }
    }

    .balance-card-container {
      z-index: 2;
      padding: 3px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px,
        rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
        rgba(0, 0, 0, 0.01) 0px 24px 32px;
      background-color: white;
      &:hover {
        .balance-card {
          transition: 0.2s all ease;
        }
      }

      .balance-card {
        overflow: hidden;
        position: relative;
        height: 160px;
        width: 100%;
        padding: 20px;
        margin-bottom: 3px;
        border-radius: 15px;
        background-color: white;
        img {
          height: 50px;
        }
        &.eth {
        }
        &.hodlc {
        }
        &.hodlt {
        }
        .top {
          display: flex;
          flex-direction: row;
          align-items: center;

          .title {
            // color: white;
            color: rgb(40, 40, 40);
            font-weight: 500;
            padding: 0 10px;
            font-size: 1.5rem;
            font-weight: 700;
          }
        }
        .bottom {
          margin-top: 18px;

          .number {
            font-size: 1.3rem;
            color: black;
            font-weight: 500;
            padding-bottom: 5px;
            .fiat {
              margin-top: 5px;
              display: inline-block;
              color: rgba(0, 0, 0, 0.5);
              font-size: 1.1rem;
            }
            @media screen and (max-width: map-get($breakpoints, large)) {
              font-size: 1.5rem;
              padding: 0.5rem 0;
            }
            @media screen and (max-width: map-get($breakpoints, medium)) {
              font-size: 1.3rem;
              padding: 0.2rem 0;
            }
            @media screen and (max-width: map-get($breakpoints, small)) {
              font-size: 1rem;
            }
          }
          .t-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 5px;
            .number {
              font-size: 1.1rem;
            }
            .ui.button {
              margin: 0px;
              opacity: 0.7;
              color: black;
              border-radius: 5px;
              &:hover {
                opacity: 0.8;
              }
              &:active {
                transform: scale(0.97);
              }
            }
          }
          @media screen and (max-width: map-get($breakpoints, large)) {
            margin-top: 30px;
          }
          @media screen and (max-width: map-get($breakpoints, medium)) {
            margin-top: 20px;
          }
        }
        .logo-mark {
          position: absolute;
          opacity: 0.3;
          top: 45px;
          right: 15px;
          height: 150px;
          @media screen and (max-width: map-get($breakpoints, large)) {
            width: 200px;
            right: 14%;
          }
          @media screen and (max-width: map-get($breakpoints, medium)) {
            width: 150px;
            right: 20px;
          }
        }
        @media screen and (max-width: map-get($breakpoints, large)) {
          width: 100%;
          height: 180px;
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          height: 160px;
          width: 100%;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          height: 180px;
          width: 100%;
        }
      }
      .btn-group {
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .ui.button {
          width: 100%;
          margin: 0px;
          opacity: 0.7;
          color: black;
          border-radius: 5px;
          &:first-child {
            margin-right: 3px;
          }
          &:hover {
            opacity: 0.8;
          }
          &:active {
            transform: scale(0.97);
          }
          &.trade-disabled,
          .trade-disabled:active,
          .trade-disabled:hover {
            opacity: 0.8;
            transform: none;
            opacity: 0.7;
            color: black;
          }
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          flex-direction: column;
          justify-content: center;
          .ui.button:first-child {
            margin-bottom: 8px;
          }
        }
        .fluid.button.wallet-action-button {
          background: rgba(0, 0, 0, 0.05);
          &:first-child {
            border-bottom-left-radius: 15px;
          }
          &:last-child {
            border-bottom-right-radius: 15px;
          }
          &:hover {
            color: $main-color;
            background: rgba(0, 0, 0, 0.05);
          }
          &:active {
            background: rgba(0, 0, 0, 0.01);
          }
          &.trade-disabled,
          .trade-disabled:active,
          .trade-disabled:hover {
            background: rgba(0, 0, 0, 0.08);
            cursor: default;
            opacity: 0.45 !important;
            color: black;
          }
        }
      }
      @media screen and (max-width: map-get($breakpoints, large)) {
        flex-direction: column;
        justify-content: center;
      }
    }

    @media screen and (max-width: map-get($breakpoints, large)) {
      flex-direction: column;
      justify-content: center;
    }
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: column;
      justify-content: center;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 5px;
    }
  }
  .event-list {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    padding: 0px;
    margin: auto 3px;
    .event-container {
      box-shadow: inset 0px -1px rgba(black, 0.05);
      &.event-item {
        &.no-event {
          text-align: center;
          padding: 20px;
        }
        &:last-child {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
        }
        .column {
          padding: 15px 15px;
        }
      }
      .hash {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .action-label {
        display: block;
        font-weight: 700;
        color: white;
        background: rgba(0, 0, 0, 0.05);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        border-radius: 15px;
        padding: 6px;
        margin: -2px;
        &.deposit {
          color: rgb(219, 88, 255);
        }
        &.withdraw {
          color: rgb(228, 100, 179);
        }
        // issue
        &.To.HODLC {
          color: rgb(15, 199, 220);
        }
        // redeem
        &.To.HODLT {
          color: rgb(244, 90, 107);
        }
        // sell/negative event
        &.BuyOrderFilled,
        &.SellOrderOpened {
          color: white;
          background-color: $red-color;
          opacity: 0.6;
        }
        // buy/positive event
        &.SellOrderFilled,
        &.BuyOrderOpened,
        &.BuyFromReserve {
          color: white;
          background-color: $green-color;
          opacity: 0.7;
        }
        // others
        &.SellOrderRefunded,
        &.BuyOrderRefunded,
        &.SellOrderCancelled,
        &.BuyOrderCancelled {
          color: rgba(black, 0.7);
          opacity: 0.8;
        }
      }
      .status {
        display: flex;
        flex-direction: row;
        .dot {
          display: inline;
          margin-top: -0.4px;
          margin-right: 5px;
          font-size: 1rem;
          color: #00d087;
        }
      }
      .sm-label {
        display: none;
        margin-bottom: 2px;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
      .amount {
        text-align: right;
        line-height: 20px;
        .light {
          opacity: 0.2;
        }
        .unit-span {
          display: inline-block;
          width: 70px;
          padding-left: 20px;
          text-align: left;
        }
        span {
          font-weight: 700;
        }
        @media screen and (max-width: 768px) {
          text-align: left;
        }
      }
      .ui.stackable.grid > .wide.column {
        @media screen and (max-width: 768px) {
          padding: 5px !important;
        }
      }
      .ui.stackable.grid {
        @media screen and (max-width: 768px) {
          padding: 10px;
        }
      }
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
    .history-header {
      background: white;
      font-weight: 700;
      text-transform: capitalize;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding: 15px;
      .hash {
        width: 100px;
      }
      .amount {
        text-align: right;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
