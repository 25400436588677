@import '../../assets/scss/variable.scss';

.event-list-component {
  .ui.header.session-header {
    display: inline-block;
    margin-bottom: 20px;
  }
  .true-user-address {
    opacity: 0.4;
    text-decoration: underline dotted $main-color;
  }
  .time-selector {
    display: inline-block;
    select {
      display: inline-block;
      border-radius: 5px;
      padding: 2px 5px;
      margin-left: 10px;
      border: none;
      background: rgba(0, 0, 0, 0.05);
      font-weight: 700;
      opacity: 0.7;
      cursor: pointer;
      outline: none;
      &:hover {
        opacity: 1;
      }
    }
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}
