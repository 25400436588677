.graph-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .container {
    padding: 30px 5px;
    .session-body {
      padding: 55px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 1px 50px -10px rgba(107, 87, 87, 0.125);
      @media (max-width: 767px) {
        padding: 20px;
      }
    }
    .header.session-header {
      display: inline-block;
      margin-bottom: 20px;
      font-size: 1.1rem;
    }
  }
}

.time-selector.explorer.graph {
  margin: 0 10px;
}
