.trade-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  .container {
    padding: 20px 5px;
  }
  .dashboard-session {
    padding: 10px 0px;
    .header.session-header {
      margin-left: 10px;
      text-align: left;
      font-family: 'Open Sans', 'Roboto', sans-serif;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem;
    }
  }
  .no-event {
    text-align: center;
    margin: 0;
    opacity: 0;
    color: $main-color;
    font-weight: 600;
    &:first-child {
      margin: auto;
      opacity: 1;
    }
    .loading-component {
      margin: auto;
    }
    .progress-indicator {
      margin: -20px auto 20px;
    }
  }
  .trade-info-container {
    display: flex;
    flex-direction: row;
    min-height: 183px;
    position: relative;
    .trade-info-blocker {
      display: none;
      transition: 0.5s all ease;
    }
    &:hover {
      @media (min-width: 767px) {
        .trade-info-blocker.true {
          transition: 0.5s all ease;
          display: block;
          position: absolute;
          width: 99%;
          height: 100%;
          z-index: 4;
          background: rgba(white, 0.5);
          backdrop-filter: blur(2px);
          border-radius: 8px;
          text-align: center;
          button {
            margin: auto;
            margin-top: 60px;
            border-radius: 10px;
            font-size: 1.3rem;
            color: $main-color;
            &:hover {
              .metamask-icon {
                transform: translateY(-2px) scale(1.2);
              }
            }
            .metamask-icon {
              margin-left: 5px;
              transform: translateY(-2px) scale(1);
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .trade-info-blocker.true {
        transition: 0.5s all ease;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: rgba(white, 0.5);
        backdrop-filter: blur(2px);
        border-radius: 8px;
        text-align: center;
        button {
          margin: auto;
          margin-top: 120px;
          border-radius: 10px;
          font-size: 1.3rem;
          color: $main-color;
          &:hover {
            .metamask-icon {
              transform: translateY(-2px) scale(1.2);
            }
          }
          .metamask-icon {
            margin-left: 5px;
            transform: translateY(-2px) scale(1);
          }
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: column;
      min-height: 350px;
      .trade-card.trade-info,
      .trade-card.trade-action {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .info-header {
      text-align: left;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1.3px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1.1rem;
      padding: 0px 5px;
      margin: 0;
    }
    .trade-card {
      display: flex;
      flex-direction: column;
      background-color: white;
      justify-content: space-evenly;
      padding: 10px;
      width: 50%;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px,
        rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
        rgba(0, 0, 0, 0.01) 0px 24px 32px;
      .trade-group {
        display: flex;
        flex-direction: row;
      }
      &.trade-info {
        @media screen and (max-width: map-get($breakpoints, medium)) {
          padding-top: 20px;
        }
        .info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: black;
          padding: 10px;
          padding-left: 5px;
          height: 30%;
          align-items: center;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            flex-direction: column;
            align-items: start;
            span {
              display: block;
              text-align: right;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .title {
            font-size: 1.4rem;
            font-weight: 700;
          }
          span {
            font-family: 'Roboto Mono' !important;
            font-size: 1.3rem;
          }
        }
      }
      &.trade-action {
        background-color: white;
        padding: 0px;
        margin-right: 10px;
        .btn-group {
          margin-top: -3px;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            margin-top: 0px;
          }
          button {
            border-radius: 0px;
            background: rgba(black, 0.05);
            &:hover {
              background: rgba(black, 0.1);
            }
            &:active {
              background: rgba(black, 0.05);
            }
            &.true {
              opacity: 1;
              color: black;
              background-color: white;
              z-index: 3;
            }
            &.left {
              border-top-left-radius: 15px;
              margin-right: 0px;
              color: $green-color;
              &.true {
                color: $green-color;
                border-top-right-radius: 15px;
              }
            }
            &.right {
              border-top-right-radius: 15px;
              margin-right: 0px;
              color: $red-color;
              &.true {
                color: $red-color;
                border-top-left-radius: 15px;
              }
            }
          }
        }
        .trade-form {
          padding: 10px;
          background-color: white;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          // box-shadow: 2px -2px 2px 0px rgba(0, 0, 0, 0.1);
          .max-min-btn {
            padding: 0;
            font-weight: 600;
            user-select: none;
            cursor: pointer;
            margin-right: 3px;
            opacity: 0.6;
            &:hover {
              opacity: 1;
              text-decoration: underline;
            }
          }
          .divider {
            font-weight: 200;
            user-select: none;
            cursor: pointer;
            margin-right: 3px;
            opacity: 0.6;
          }
          .max-min-btn-devider {
            margin: 0px 5px;
            margin-right: 8px;
            width: 1px;
            background: rgba(black, 0.1);
          }
          .main-input {
            border: 1px solid rgba(224, 225, 226, 0.4);
            background: rgba(0, 0, 0, 0.05);
            padding: 10px;
            border-radius: 8px;
            outline: none;
          }
          .sub-input {
            padding: 10px 3px;
            color: black;
            font-weight: 600;
            small {
              margin-left: 3px;
              opacity: 0.7;
            }
            input {
              text-align: right;
              margin-right: 5px;
              font-weight: 800;
              font-family: 'Roboto Mono' !important;
            }
          }
          .trade-action-button {
            border-radius: 8px;
            color: white;
            &.Buy {
              background: $green-color;
              &:hover {
                background: lighten($green-color, 1%);
              }
              &:active {
                background: darken($green-color, 1%);
              }
            }
            &.Sell {
              background: $red-color;
              &:hover {
                background: lighten($red-color, 5%);
              }
              &:active {
                background: darken($red-color, 5%);
              }
            }
          }
        }
      }
    }
  }
  .order-book {
    height: 100%;
    .menu {
      cursor: pointer;
    }
    &.order-switcher {
      padding-top: 20px;
      .item {
        font-weight: 700;
        padding: 10px 20px;
        background: rgba(black, 0.05);
        border-radius: 100px;
        margin: 0px 5px;
        &:hover {
          border-radius: 100px;
          background: rgba(black, 0.1);
        }
        &:active {
          transform: scale(0.97);
        }
        &.active {
          background: rgba($main-color, 1);
          color: white;
          border-radius: 100px;
          &:hover {
            color: white;
            background: rgba($main-color, 1);
          }
        }
      }
    }
    .orders-container {
      border-radius: 15px;
      background: rgba(white, 1);
      box-shadow: inset rgba(0, 0, 0, 0.01) 0px 0px 1px;
      .order-section {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        padding: 15px 0px 50px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          flex-direction: column;
        }
        .book {
          width: 100%;
          padding: 0px;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            width: 100%;
          }
          .info-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px 10px 10px;
            @media screen and (max-width: map-get($breakpoints, medium)) {
              flex-direction: column;
              align-items: start;
            }
            .limit-selector {
              font-weight: 700;
              display: flex;
              @media screen and (max-width: map-get($breakpoints, medium)) {
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding-top: 5px;
                select {
                  width: 100%;
                }
                .button-group-container {
                  width: 100%;
                  justify-content: space-between;
                }
                button {
                  &:first-child {
                    margin-left: 0px;
                  }
                  &:last-child {
                    margin-right: 0px;
                  }
                }
                button,
                span.pagination-indicator {
                  flex-grow: 1;
                }
              }
              .button-group-container {
                display: flex;
              }
              button {
                &.prev span {
                  display: inline-block;
                  transform: rotate(30deg) translateY(-1px);
                }
                &.next span {
                  display: inline-block;
                  transform: rotate(330deg) translateY(-1px);
                }
              }
              select,
              button,
              span.pagination-indicator {
                user-select: none;
                align-items: stretch;
                display: inline-block;
                border-radius: 5px;
                padding: 2px 10px;
                margin: 2px;
                border: none;
                background: rgba(0, 0, 0, 0.1);
                font-weight: 700;
                opacity: 0.7;
                cursor: pointer;
                outline: none;
                height: 25px;
                &:hover {
                  opacity: 1;
                }
                &:disabled {
                  opacity: 0.5;
                  &:hover {
                    cursor: wait;
                    opacity: 0.5;
                  }
                }
                &.disabled-true {
                  opacity: 0.5;
                  &:hover {
                    cursor: default !important;
                  }
                }
              }
            }
            .order-count {
              text-align: left;
              padding-left: 10px;
              display: flex;
              flex-direction: row;
              .last-updated-indicator {
                opacity: 0.6;
                margin: 0px 5px;
                display: flex;
                align-items: center;
                .text {
                  margin: 0px 5px;
                  font-size: 0.9rem;
                }
                img {
                  height: 17px;

                  &.spin-0 {
                    animation-name: spin;
                    animation-duration: 1000ms;
                    animation-timing-function: linear;
                  }
                }
              }
            }
          }
          .list-component {
            display: block;
          }
          &.sell-book {
          }
          &.buy-book {
          }

          .order {
            user-select: none;
            margin: 0px 0px;
            border-radius: 0px;
            padding: 10px 20px;
            position: relative;
            overflow: hidden;
            box-shadow: inset 0px -1px rgba(black, 0.05);
            @media screen and (max-width: map-get($breakpoints, medium)) {
              padding: 15px;
            }
            &:hover {
              //box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
            }
            .price-row .price {
              font-weight: 500;
            }
            .wide.column {
              font-size: 1.1rem;
              @media screen and (max-width: map-get($breakpoints, medium)) {
                padding: 5px !important;
              }
              &.cancel-column {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .action {
              &.false .cancel-your-order-btn {
                display: none;
              }
              &.true {
                .cancel-your-order-btn {
                  text-transform: uppercase;
                  padding: 6px 15px;
                  margin: 0px;
                  font-size: 0.8rem;
                  transform: translateY(-1px);
                  border-radius: 8px;
                  @media screen and (max-width: 768px) {
                    font-size: 1rem;
                    width: 100%;
                    display: block;
                    padding: 10px 10px;
                    color: white;
                    background-color: $red-color;
                    opacity: 0.9;
                  }
                  &:hover {
                    color: white;
                    background-color: $red-color;
                    transform: scale(0.95);
                  }
                  &:active {
                    transform: scale(0.9);
                  }
                  &.disable-cancel,
                  .disable-cancel:hover,
                  .disable-cancel:active {
                    transform: none;
                    background-color: #e0e1e2;
                    color: rgba(0, 0, 0, 0.6);
                    opacity: 0.45 !important;
                    cursor: default;
                  }
                }
              }
            }
            .amount {
              // text-align: right;
              line-height: 20px;
              .light {
                opacity: 0.2;
              }
              .unit-span {
                display: inline-block;
                width: 70px;
                padding-left: 20px;
                text-align: left;
              }
              span {
                font-weight: 700;
              }
              @media screen and (max-width: 768px) {
                text-align: left;
              }
            }

            div {
              // max-width: 25%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .sm-label {
              display: none;
              margin-bottom: 2px;
              font-weight: 700;
              @media screen and (max-width: 768px) {
                display: block;
              }
            }
            .order-id {
              text-transform: capitalize;
            }
            .user {
              // background: green;
              text-transform: capitalize;
            }
            .amount {
              // background: blue;
              text-transform: capitalize;
            }
            .price {
              text-transform: capitalize;
              // background: yellow;
            }
            &.order-header {
              font-weight: bold;
              background: none;
              @media screen and (max-width: 768px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
