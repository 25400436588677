$breakpoints: (
  small: 480px,
  medium: 850px,
  large: 1000px,
  x-large: 1130px
);

$blue-color: rgb(0, 85, 232);
$red-color: rgb(239, 21, 82);
$green-color: rgb(0, 191, 199);
$orange-color: rgb(255, 60, 0);

$main-color: rgb(42, 100, 123);
$background-color: rgba(49, 131, 137, 0.02);

.calulator-component {
  margin-top: 15px;
  max-width: 900px;
  .session-body {
    padding: 55px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 50px -10px rgba(107, 87, 87, 0.125);
    @media (max-width: 767px) {
      padding: 20px;
    }
    .session-header {
      margin-left: 0px;
    }
    .info-session {
      padding-bottom: 50px;
      font-size: 1.2rem;
      .result-text {
        margin-top: 30px;
        font-size: 1.4rem;
        //font-weight: 700;
      }
    }
    form {
      .btn.simulate-btn {
        font-weight: bold;
        color: white;
        background-color: $main-color;
        border-radius: 100px;
        &:hover {
          background-color: lighten(rgba($main-color, 1), 10%);
          color: white;
          transition: 0.1s all;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      select {
        display: inline-block;
        border-radius: 6px;
        padding: 5px 15px;
        background: rgba(0, 0, 0, 0);
        border: 2px solid rgba(0, 0, 0, 0.1);
        font-weight: 700;
        cursor: pointer;
        outline: none;
        margin: 5px;
        height: 40px;
        &:hover {
          outline: none;
          border: 2px solid rgba(black, 0.4);
        }
        &:active,
        &:focus {
          outline: none;
          border: 2px solid $main-color;
        }
      }
      input {
        border-radius: 6px;
        margin: 5px;
        border: 2px solid rgba(black, 0.2);
        padding: 5px 5px 5px 15px;
        display: inline-block;
        width: 125px;
        height: 40px;
        &:hover {
          outline: none;
          border: 2px solid rgba(black, 0.4);
        }
        &:active,
        &:focus {
          outline: none;
          border: 2px solid $main-color;
        }
      }
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
      small.notes {
        background: rgba($main-color, 0.2);
        box-shadow: inset 0px 0px 0px 2px rgba($main-color, 0.6);
        color: $main-color;
        padding: 5px 10px;
        border-radius: 5px;
        display: inline-block;
        margin: 5px 0px;
      }
    }
    .chart-container {
      width: 100%;
      height: 500px;
      .recharts-wrapper {
        border-radius: 8px;
        //box-shadow: 0px 1px 50px -10px rgba(107, 87, 87, 0.125);
      }
      .recharts-legend-wrapper {
        bottom: -30px;
      }
      .recharts-surface {
        margin-left: -20px;
      }
      //.recharts-legend-wrapper {
      //  transform: translateY(30px);
      //}
      .recharts-legend-item.legend-item-0 {
        transform: translateX(-30px);
      }
    }
  }
}

.calculator-type-switcher {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: inline-flex;
  width: auto;
  margin: 5px auto 20px;

  @media screen and (max-width: map-get($breakpoints, medium)) {
    display: flex;
  }
  a.item {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    padding: 6px 30px;
    font-weight: 700;
    text-transform: capitalize;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 50%;
      text-align: center;
      padding: 6px 15px;
    }
    &:hover {
      color: $main-color;
      background: rgba(0, 0, 0, 0.08);
    }
    &:active {
      background: rgba(0, 0, 0, 0.05);
    }
    &.hodling {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
    &.cashout {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
    &.true {
      transition: 0.1s all ease-in-out;
      background: $main-color;
      color: white;
      &:hover {
        background: rgba($main-color, 0.95);
      }
    }
  }
}

.disclaimer-paragraph {
  text-align: justify;
  opacity: 0.8;
  font-size: 1.1rem;
  line-height: 1.6rem;
}
